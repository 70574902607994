export const TEST_INIT = '@@frontend/store/reducers/app/TEST_INIT';
export const SET_CHARGE_CODE = '@@frontend/store/reducers/app/SET_CHARGE_CODE';
export const CLEAR_CHARGE_CODE = '@@frontend/store/reducers/app/CLEAR_CHARGE_CODE';
export const SET_CHARGES = '@@frontend/store/reducers/app/SET_CHARGES';
export const SET_CHARGES_SUCCESS = '@@frontend/store/reducers/app/SET_CHARGES_SUCCESS';
export const SET_CHARGES_FAILURE = '@@frontend/store/reducers/app/SET_CHARGES_FAILURE';
export const SET_CRYPTOCURRENCY = '@@frontend/store/reducers/app/SET_CRYPTOCURRENCY';
export const SET_CRYPTOCURRENCY_SUCCESS =
  '@@frontend/store/reducers/app/SET_CRYPTOCURRENCY_SUCCESS';
export const SET_CRYPTOCURRENCY_FAILURE =
  '@@frontend/store/reducers/app/SET_CRYPTOCURRENCY_FAILURE';
export const SET_CHARGE_LIST = '@@frontend/store/reducers/app/SET_CHARGE_LIST';
export const SET_CHARGE_LIST_SUCCESS = '@@frontend/store/reducers/app/SET_CHARGE_LIST_SUCCESS';
export const SET_CHARGE_LIST_FAILURE = '@@frontend/store/reducers/app/SET_CHARGE_LIST_FAILURE';
export const CLEAR_PASSCODE_LOCK = '@@frontend/store/reducers/app/CLEAR_PASSCODE_LOCK';
export const SET_PASSCODE_LOCK = '@@frontend/store/reducers/app/SET_PASSCODE_LOCK';
export const SET_PASSCODE_LOCK_SUCCESS = '@@frontend/store/reducers/app/SET_PASSCODE_LOCK_SUCCESS';
export const SET_PASSCODE_LOCK_FAILURE = '@@frontend/store/reducers/app/SET_PASSCODE_LOCK_FAILURE';
export const SET_PRODUCT_LIST = '@@frontend/store/reducers/app/SET_PRODUCT_LIST';
export const SET_PRODUCT_LIST_SUCCESS = '@@frontend/store/reducers/app/SET_PRODUCT_LIST_SUCCESS';
export const SET_PRODUCT_LIST_FAILURE = '@@frontend/store/reducers/app/SET_PRODUCT_LIST_FAILURE';
export const ADD_PRODUCT_CART = '@@frontend/store/reducers/app/ADD_PRODUCT_CART';
export const REMOVE_PRODUCT_CART = '@@frontend/store/reducers/app/REMOVE_PRODUCT_CART';
export const CLEAR_PRODUCT_CART = '@@frontend/store/reducers/app/CLEAR_PRODUCT_CART';
export const SET_THEME = '@@frontend/store/reducers/app/SET_THEME';
export const SET_ADDRESS = '@@frontend/store/reducers/app/SET_ADDRESS';
export const SET_ADDRESS_SUCCESS =
  '@@frontend/store/reducers/app/SET_ADDRESS_SUCCESS';
export const SET_ADDRESS_FAILURE =
  '@@frontend/store/reducers/app/SET_ADDRESS_FAILURE';
export const SET_MERCHANT_ID = '@@frontend/store/reducers/app/SET_MERCHANT_ID';