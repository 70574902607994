import _ from 'lodash';
import {
  SET_CHARGE_CODE,
  CLEAR_CHARGE_CODE,
  SET_CHARGES,
  SET_CHARGES_SUCCESS,
  SET_CHARGES_FAILURE,
  SET_CRYPTOCURRENCY,
  SET_CRYPTOCURRENCY_SUCCESS,
  SET_CRYPTOCURRENCY_FAILURE,
  SET_CHARGE_LIST,
  SET_CHARGE_LIST_SUCCESS,
  SET_CHARGE_LIST_FAILURE,
  CLEAR_PASSCODE_LOCK,
  SET_PASSCODE_LOCK,
  SET_PASSCODE_LOCK_SUCCESS,
  SET_PASSCODE_LOCK_FAILURE,
  SET_PRODUCT_LIST,
  SET_PRODUCT_LIST_SUCCESS,
  SET_PRODUCT_LIST_FAILURE,
  ADD_PRODUCT_CART,
  REMOVE_PRODUCT_CART,
  CLEAR_PRODUCT_CART,
  SET_THEME,
  SET_ADDRESS,
  SET_ADDRESS_SUCCESS,
  SET_ADDRESS_FAILURE,
  SET_MERCHANT_ID
} from 'store/actions';
import { ReducerAction, StateBase } from '.';
import { ChargeBody } from 'api/interfaces/chargeBody.interface';

interface AppReducerStateProps extends StateBase {
  test: boolean;
  newChargeBody: ChargeBody | undefined;
  chargeCurrency: string;
  charge: any;
  chargeList: any[];
  productList: any[];
  categoryList: any[];
  categoryVIPList: any[];
  cart: any[];
  passcodeLock: boolean;
  passcode: string;
  token: string;
  theme: any;
}

const DEFAULT_STATE: AppReducerStateProps = {
  test: false,
  loading: false,
  error: '',
  newChargeBody: undefined,
  chargeCurrency: 'EUR',
  charge: null,
  chargeList: [],
  productList: [],
  categoryList: [],
  categoryVIPList: [],
  cart: [],
  passcodeLock: false,
  passcode: '',
  token: '',
  theme: null
};

const appReducer = (state = DEFAULT_STATE, { type, payload }: ReducerAction) => {
  switch (type) {
    case SET_CHARGE_CODE:
      return {
        ...state,
        newChargeBody: payload,
        error: null
      };
    case CLEAR_CHARGE_CODE:
      return {
        ...state,
        chargeAmount: null
      };
    case SET_CHARGES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SET_CHARGES_SUCCESS:
      return {
        ...state,
        loading: false,
        charge: payload,
        chargeCode: payload.id
      };
    case SET_CHARGES_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case SET_CRYPTOCURRENCY:
      return {
        ...state,
        loading: true,
        chargeCode: payload,
        error: null
      };
    case SET_CRYPTOCURRENCY_SUCCESS:
      return {
        ...state,
        loading: false,
        charge: payload
      };
    case SET_CRYPTOCURRENCY_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case SET_ADDRESS:
      return {
        ...state,
        loading: true,
        selectedCrypto: payload,
        error: null
      };
    case SET_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCrypto: null,
        charge: payload
      };
    case SET_ADDRESS_FAILURE:
      return {
        ...state,
        loading: false,
        selectedCrypto: null,
        error: payload
      };
    case SET_CHARGE_LIST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SET_CHARGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        chargeList: payload
      };
    case SET_CHARGE_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case SET_PASSCODE_LOCK:
      return {
        ...state,
        loading: true,
        passcode: payload.password,
        merchantId: payload.merchantId,
        error: null
      };
    case CLEAR_PASSCODE_LOCK:
      return {
        ...state,
        loading: true,
        passcode: '',
        token: '',
        tokenError: '',
        error: null
      };
    case SET_PASSCODE_LOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        token: payload
      };
    case SET_PASSCODE_LOCK_FAILURE:
      return {
        ...state,
        loading: false,
        tokenError: payload
      };
    case SET_PRODUCT_LIST:
      return {
        ...state,
        loading: true,
        error: null
      };
    case SET_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        productList: _.sortBy(payload, ['name']),
        categoryList: [
          ...Array.from(
            new Set(
              _.sortBy(
                payload
                  .filter((product: any) => product.parentCategory === null)
                  .map((p: any) => p.category)
              )
            )
          )
        ],
        categoryVIPList: [
          ...Array.from(
            new Set(
              _.sortBy(
                payload
                  .filter((product: any) => product.parentCategory === 'VIP')
                  .map((p: any) => p.category)
              )
            )
          )
        ]
      };
    case SET_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload
      };
    case ADD_PRODUCT_CART:
      return {
        ...state,
        cart: [...state.cart, payload]
      };
    case REMOVE_PRODUCT_CART:
      const index = state.cart.findIndex((item) => item.id === payload);
      const mutArr = [...state.cart];
      mutArr.splice(index, 1);
      return {
        ...state,
        cart: [...mutArr]
      };
    case CLEAR_PRODUCT_CART:
      return {
        ...state,
        cart: []
      };
    case SET_THEME:
      return {
        ...state,
        theme: payload
      };
    case SET_MERCHANT_ID:
      return {
        ...state,
        merchantId: payload
      };
    default:
      return state;
  }
};

export { appReducer };
export type { AppReducerStateProps };
