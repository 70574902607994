import { InvoicesContainer } from './styled';
import { FC } from 'react';

const Invoices: FC = () => {
  return (
    <InvoicesContainer>
      <h1>Invoices</h1>
    </InvoicesContainer>
    
  );
};

export default Invoices;
