import { NavigationItem } from 'utils/interfaces/navigation.interface';
import ChargeIcon from 'assets/icons/ChargeIcon';
import LockIcon from 'assets/icons/LockIcon';
import InvoiceIcon from 'assets/icons/InvoiceIcon';
import PaymentIcon from 'assets/icons/PaymentIcon';
import { CLEAR_PASSCODE_LOCK } from 'store/actions';

export const navData: NavigationItem[] = [
  {
    name: 'LOCK',
    path: 'lock',
    icon: <LockIcon />,
    onClick: (dispatch: any) => {
      dispatch({ type: CLEAR_PASSCODE_LOCK });
    }
  },
  {
    name: 'CHARGE',
    path: 'charge',
    icon: <ChargeIcon />,
    onClick: (dispatch: any) => {}
  },
  {
    name: 'POS',
    path: 'pos',
    icon: <InvoiceIcon />,
    onClick: (dispatch: any) => {}
  },
  {
    name: 'PAYMENTS',
    path: 'payments',
    icon: <PaymentIcon />,
    onClick: (dispatch: any) => {}
  }
];
