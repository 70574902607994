import styled from 'styled-components';
import { fontSizes } from 'assets/theme/typography';
import Lottie from 'lottie-react';

interface Theme {
  themeColors: any;
}

export const QRCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  margin-top: 2rem;
`;

export const QRCodeImg = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  min-height: 188px;
`;

export const QRCodeBorderTop = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 30px;
`;
export const QRCodeBorderLeftTop = styled.div<Theme>`
  display: flex;
  width: 30px;
  height: 80%;
  border-top: 1px solid ${({ themeColors }) => themeColors?.QRCodeBorder || '#FFFFFF'};
  border-left: 1px solid ${({ themeColors }) => themeColors?.QRCodeBorder || '#FFFFFF'};
`;
export const QRCodeBorderRightTop = styled.div<Theme>`
  display: flex;
  width: 30px;
  height: 100%;
  border-top: 1px solid ${({ themeColors }) => themeColors?.QRCodeBorder || '#FFFFFF'};
  border-right: 1px solid ${({ themeColors }) => themeColors?.QRCodeBorder || '#FFFFFF'};
`;

export const QRCodeBorderBottom = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 30px;
`;
export const QRCodeBorderLeftBottom = styled.div<Theme>`
  display: flex;
  width: 30px;
  height: 100%;
  border-bottom: 1px solid ${({ themeColors }) => themeColors?.QRCodeBorder || '#FFFFFF'};
  border-left: 1px solid ${({ themeColors }) => themeColors?.QRCodeBorder || '#FFFFFF'};
`;
export const QRCodeBorderRightBottom = styled.div<Theme>`
  display: flex;
  width: 30px;
  height: 100%;
  border-bottom: 1px solid ${({ themeColors }) => themeColors?.QRCodeBorder || '#FFFFFF'};
  border-right: 1px solid ${({ themeColors }) => themeColors?.QRCodeBorder || '#FFFFFF'};
`;
export const QRCodeAddress = styled.div`
  display: flex;
  width: 100%;
  margin-top: 1rem;
  font-size: ${fontSizes.small};
  letter-spacing: 1px;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  margin-bottom: 100px;
`;

export const LoadingLottieAnimation = styled(Lottie)`
  display: flex;
  height: 50px;
`;
