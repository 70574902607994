import { AuthenticationResult, InteractionStatus, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest } from 'index';

export const authResult: (msalInstance: PublicClientApplication, isAuthenticated: boolean, inProgress: InteractionStatus) => Promise<string> = async (
    msalInstance,
    isAuthenticated,
    inProgress
) => {
    if (!isAuthenticated && inProgress === InteractionStatus.None)
    {
        await msalInstance.loginRedirect(loginRequest);
        return '';
    }

    try {
        const authResult: AuthenticationResult = await msalInstance.acquireTokenSilent(loginRequest);
        return authResult.accessToken;
    } catch (error) {
        if (inProgress === InteractionStatus.None)
        {
            await msalInstance.loginRedirect(loginRequest);
        }
        return '';
    }
};
