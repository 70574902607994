import { FC, useState } from 'react';
import { PaymentsContainer, PaymentAmount, PaymentCaption, PaymentTotal } from '../styled';
import Cryptocurrency from 'components/Cryptocurrency';
import QrCodeWrapper from 'components/QrCode';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';

type NewStateProps = {
  charge: any;
  addressDetail: string;
  setAddressDetail: any;
};

const NewState: FC<NewStateProps> = ({ charge, addressDetail, setAddressDetail }) => {
  const totalAmount = charge?.amount;
  const localCurrency = charge?.currency;

  return (
    <PaymentsContainer>
      <PaymentAmount>
        <PaymentCaption>Payment details</PaymentCaption>
        <PaymentTotal>
          TOTAL: {localCurrency ? CurrencySymbolsLookUp[localCurrency] : ''}
          {formatNumber(totalAmount, 2)}
          {addressDetail &&
            ` | ${charge.pricing[addressDetail]?.amount} ${addressDetail}`}
        </PaymentTotal>
      </PaymentAmount>
      <Cryptocurrency onClick={setAddressDetail} />
      <QrCodeWrapper currency={addressDetail} />
    </PaymentsContainer>
  );
};

export default NewState;
