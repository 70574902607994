import styled from 'styled-components';
import Lottie from 'lottie-react';

export const CryptocurrencyContainer = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 2rem;
  margin-top: 2rem;
  flex-wrap: wrap;
  width: 100%;
`;

export const LoadingLottieAnimation = styled(Lottie)`
  display: flex;
  height: 50px;
`;
