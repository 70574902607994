import styled, { keyframes, css } from 'styled-components';

interface IButtonPropsType {
  background: boolean;
  passwordError: boolean;
}

const shake = keyframes`
10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`

export const PasswordContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;  
`;

export const PasswordItem = styled.div<IButtonPropsType>`
  ${props => props.passwordError ? 'border: 1px solid red' : 'border: 1px solid white' };
  border-radius: 50%;
  width: 11px;
  height: 11px;
  animation: ${props => (props.passwordError ? css`${shake} 0.82s cubic-bezier(.36,.07,.19,.97) both` : '')} ;
  ${props =>props.passwordError ? 'background-color: red;' : props.background ? 'background-color: white' : 'background-color: transparent;' }
`;

