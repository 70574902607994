import styled from 'styled-components';
import { ButtonProps } from 'components/Buttons/button.interface';
import { fontSizes } from 'assets/theme/typography';

export const ButtonStyled = styled.button<ButtonProps>`
  display: flex;
  position: relative;
  width: 75%;
  max-width: 300px;
  border: 1px solid ${({ borderColor }) => borderColor || '#ffffff'};
  font-size: ${fontSizes.regular};
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
  background-color: transparent;
  color: ${({ textColor }) => textColor || '#ffffff'};
  border-radius: 50px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  svg{
    position: absolute;
    right: 15px;
  }
  }
`;
