/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FC, useEffect } from 'react';
import { Outlet } from 'react-router';
import {
  SET_MERCHANT_ID
} from 'store/actions';

const MerchantIdWrapper: FC = () => {
  const params = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: SET_MERCHANT_ID, payload: params.merchantId });
  }, []);

  return (
    <>
      <Outlet />
    </>
  );
};

export default MerchantIdWrapper;
