import { 
  PasswordContainer, 
  PasswordItem 
} from './styled'


type Props = {
  passwordLength: any;
  passwordError: boolean;
}

const Passcode = (props: Props) => {
  const { passwordLength, passwordError } = props;
  let passwordData= Array(4).fill(false).fill(true,0,passwordLength);

  return (
    <PasswordContainer>
        {passwordData.map((password, key) => 
          <PasswordItem key={key} background={password} passwordError={passwordError}></PasswordItem>
          )}
    </PasswordContainer>
  )
}

export default Passcode