import React, { FC } from 'react';
import { LayoutContainer, LayoutViewsContainer } from './styled';

export interface LayoutProps {
  children: React.ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <LayoutContainer>
      {/* <LCCBreadcrumbs /> */}
      <LayoutViewsContainer>{children}</LayoutViewsContainer>
    </LayoutContainer>
  );
};

export default Layout;
