import React, { useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { KeyboardItem } from 'utils/interfaces/keyboard.interface';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Tooltip from '@mui/material/Tooltip';
import { KeyboardContainer, KeyboardButton, IconContainer } from './styled';

type Props = {
  onInput: any;
};

const Keyboard = (props: Props) => {
  const theme = useSelector((state: RootStateOrAny) => state?.app?.theme);
  const [password, setPassword] = useState<string>('');
  const { onInput } = props;

  const clickPasscode = async (p: string) => {
    if (password.length === 4) {
      setPassword(p);
      onInput(p);
      return;
    }
    setPassword(password + p);
    onInput(password + p);
  };

  const ClearPasscode = () => {
    setPassword(password.slice(0, -1));
    onInput(password.slice(0, -1));
  };

  const keyboardItems: KeyboardItem[] = [
    {
      item: <>1</>,
      color: '',
      background: false,
      onClick: () => clickPasscode('1')
    },
    {
      item: <>2</>,
      color: theme.QRCodeBorder,
      background: false,
      onClick: () => clickPasscode('2')
    },
    {
      item: <>3</>,
      color: '',
      background: theme.keyboardBackground,
      onClick: () => clickPasscode('3')
    },
    {
      item: <>4</>,
      color: '',
      background: false,
      onClick: () => clickPasscode('4')
    },
    {
      item: <>5</>,
      color: theme.QRCodeBorder,
      background: false,
      onClick: () => clickPasscode('5')
    },
    {
      item: <>6</>,
      color: '',
      background: theme.keyboardBackground,
      onClick: () => clickPasscode('6')
    },
    {
      item: <>7</>,
      color: '',
      background: false,
      onClick: () => clickPasscode('7')
    },
    {
      item: <>8</>,
      color: theme.QRCodeBorder,
      background: false,
      onClick: () => clickPasscode('8')
    },
    {
      item: <>9</>,
      color: '',
      background: theme.keyboardBackground,
      onClick: () => clickPasscode('9')
    },
    {
      item: (
        <Tooltip title="ENTER MERCHANT ACCESS CODE" placement="top-start">
          <IconContainer>
            <QuestionMarkIcon />
          </IconContainer>
        </Tooltip>
      ),
      color: 'transparent',
      background: false,
      onClick: () => console.log('?')
    },
    {
      item: <>0</>,
      color: theme.QRCodeBorder,
      background: false,
      onClick: () => clickPasscode('0')
    },
    {
      item: (
        <IconContainer>
          <CloseIcon />
        </IconContainer>
      ),
      color: 'transparent',
      background: false,
      onClick: () => ClearPasscode()
    }
  ];

  return (
    <KeyboardContainer>
      {keyboardItems.map(({ item, color, background, onClick }, i) => (
        <KeyboardButton
          key={i}
          themeColors={theme}
          color={color || ''}
          background={background}
          onClick={() => onClick()}
        >
          {item}
        </KeyboardButton>
      ))}
    </KeyboardContainer>
  );
};

export default Keyboard;
