import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import IconButton from '@mui/material/IconButton';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { isMobile } from 'lib/helpers/mobile';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import _, { uniqueId } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { TCartItem } from 'views/BitPay/PointOfSale/PointOfSale';
import {
  POSCartItemName,
  POSCartItemPrice,
  POSCartItemQuantity,
  POSCartItemRow,
  POSCartRowRightContainer
} from 'views/BitPay/PointOfSale/styled';
import {
  OrderItemsContainer,
  OrderListButton,
  OrderListContainer,
  OrderListTitle,
  OrderListViewContainer
} from './styled';

const OrderList: FC = () => {
  const theme = useSelector((state: RootStateOrAny) => state?.app?.theme);
  const charge: any = useSelector((state: RootStateOrAny) => state?.app?.charge);
  const cart: any[] = useSelector((state: RootStateOrAny) => state?.app?.cart);
  const chargeCurrency: string = useSelector((state: RootStateOrAny) => state?.app?.chargeCurrency);
  const [showOrder, setShowOrder] = useState<boolean>(false);
  const [items, setItems] = useState<TCartItem[]>([]);

  useEffect(() => {
    mappedCartItems();
  }, []);

  const products = charge?.products;

  const setOrderList: () => void = () => {
    setShowOrder(!showOrder);
  };

  const mappedCartItems: () => void = () => {
    const groupedCartItems = _.groupBy(cart, 'id');
    setItems(
      Object.keys(groupedCartItems)
        .map(
          (productId) =>
            ({
              productId: productId,
              name: groupedCartItems[productId][0].name,
              amount: groupedCartItems[productId].reduce((sum, item) => sum + item.amount, 0),
              quantity: groupedCartItems[productId].length,
              currency: groupedCartItems[productId][0].currency,
              raw: groupedCartItems[productId][0]
            } as TCartItem)
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );
  };

  const orderListRender: () => JSX.Element = () => (
    <OrderItemsContainer>
      <OrderListTitle>ORDER LIST</OrderListTitle>
      {items.map((item) => (
        <POSCartItemRow key={uniqueId(item.productId)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <POSCartItemQuantity style={{ width: '30px' }}>{item.quantity} x </POSCartItemQuantity>
            <POSCartItemName>{item.name}</POSCartItemName>
          </div>
          <POSCartRowRightContainer>
            <POSCartItemPrice>
              {chargeCurrency ? CurrencySymbolsLookUp[chargeCurrency] : ''}
              {formatNumber(item.amount, 2)}
            </POSCartItemPrice>
          </POSCartRowRightContainer>
        </POSCartItemRow>
      ))}
    </OrderItemsContainer>
  );

  return products && products.length > 0 ? (
    <OrderListContainer onClick={setOrderList}>
      <OrderListButton show={!showOrder}>
        <SportsBarIcon /> {!isMobile() && 'View Order List'}
      </OrderListButton>
      <OrderListViewContainer
        themecolors={theme}
        variant="temporary"
        anchor={'right'}
        open={showOrder}
        onClose={setOrderList}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <IconButton onClick={setOrderList}>
          <ArrowBackIosNewIcon />
        </IconButton>
        {orderListRender()}
      </OrderListViewContainer>
    </OrderListContainer>
  ) : (
    <></>
  );
};

export default OrderList;
