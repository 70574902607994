import React, { FC } from 'react';
import { CryptocurrencyButtonContainer } from './styled';

export interface CryptocurrencyButtonButtonProps {
  text?: string;
  icon: string;
  onClick?: () => void;
}

const CryptocurrencyButton: FC<CryptocurrencyButtonButtonProps> = ({ text, icon, onClick }) => {
  return (
    <CryptocurrencyButtonContainer onClick={onClick}>
      <img src={icon} />
      {text}
    </CryptocurrencyButtonContainer>
  );
};

export default CryptocurrencyButton;
