import {
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import AdminBalancesApi from 'api/admin/balances.api';
import CryptocurrencyButton from 'components/Buttons/CryptocurrencyButton';
import { cryptoImagesLookup, cryptoNamesLookup } from 'lib/lookups/crypto.lookup';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { authResult } from 'config/authConfig';
import { RootStateOrAny, useSelector } from 'react-redux';
import { PrimaryButton } from 'components/Buttons';

type Props = {};

const BalanceList = (props: Props) => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const [balances, setBalances] = useState<any[]>([]);
  const adminBalancesApi = new AdminBalancesApi('');
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);

  useEffect(() => {
    retrieveBalances();
  }, []);

  const retrieveBalances: () => void = async () => {
    const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
    const res = await adminBalancesApi.getBalances(token);
    setBalances(res?.balances || []);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '3rem' }}>
      <KeyboardBackspaceIcon
        fontSize="large"
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/${merchantId}/admin`)}
      />
      <List sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {balances.map((b, i) => (
          <>
            <ListItem
              alignItems="flex-start"
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                borderRadius: '12px',
                cursor: 'pointer'
              }}
              sx={{ width: 360, bgcolor: '#FFFFFF30' }}
              onClick={() => navigate(`/${merchantId}/admin/balances/${b.id}/withdraw`)}
            >
              <ListItemAvatar style={{ marginTop: 0 }}>
                <Avatar>
                  <CryptocurrencyButton key={i} icon={cryptoImagesLookup[b.currency]} />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'space-between'
                }}
                primary={cryptoNamesLookup[b.currency]}
                secondary={
                  <span style={{ display: 'flex', flexDirection: 'column', color: 'white' }}>
                    <React.Fragment>
                      <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                        Available: {b.available}
                      </Typography>
                    </React.Fragment>
                    <React.Fragment>
                      <Typography sx={{ display: 'inline' }} component="span" variant="body2">
                        Balance: {b.balance}
                      </Typography>
                    </React.Fragment>
                  </span>
                }
              />
            </ListItem>
          </>
        ))}
      </List>
    </div>
  );
};

export default BalanceList;
