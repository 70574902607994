import Axios from 'axios';
import { AsyncTask } from 'api/interfaces/asyncTask.interface';

export const verifyPasscode: (
  passcode?: string | undefined,
  merchantId?: string | undefined
) => Promise<AsyncTask> = async (passcode?: string, merchantId?: string) => {
  const { data } = await Axios.get<AsyncTask>(
    `${window.API_PATH}auth/token/${merchantId}/${passcode}`
  );
  return data;
};