import styled from 'styled-components';

export const FooterContainer = styled.footer`
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 6.25rem; //100px
  background-position: top center;
  background-repeat: repeat-x;
  background-size: cover;
`;
