import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useSelector, useDispatch } from 'react-redux';
import { SET_PASSCODE_LOCK, CLEAR_PASSCODE_LOCK } from 'store/actions';
import { LoginContainer, LoginHeader } from './styled';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Passcode from 'components/Passcode';
import Keyboard from 'components/Keyboard';
import { theme } from 'assets/theme/bitpay/style';

const Login: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordError, setPasswordError] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const token: any[] = useSelector((state: RootStateOrAny) => state?.app?.token);
  const tokenError: any[] = useSelector((state: RootStateOrAny) => state?.app?.tokenError);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);

  const setPasswordAction = (p: string) => {
    setPassword(p);
    setPasswordError(false);
    if (password.length === 1) {
      dispatch({ type: CLEAR_PASSCODE_LOCK });
    }
  };

  useEffect(() => {
    if (password.length === 4) {
      dispatch({ type: SET_PASSCODE_LOCK, payload: {
        password: password,
        merchantId: merchantId
      } });
    }
  }, [password]);

  useEffect(() => {
    if (token) {
      navigate(`/${merchantId}/home`);
    }
  }, [token]);

  useEffect(() => {
    if (tokenError) {
      setPasswordError(true);
      setPassword('');
    }
  }, [tokenError]);

  return (
    <LoginContainer>
      <LoginHeader>
        <p style={{ color: theme.primary }}>ENTER MERCHANT ACCESS CODE</p>
        <InfoOutlinedIcon />
      </LoginHeader>
      <Passcode passwordLength={password.length} passwordError={passwordError} />
      <Keyboard onInput={(password: any) => setPasswordAction(password)} />
    </LoginContainer>
  );
};

export default Login;
