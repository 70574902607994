import { font, fontWeights, fontSizes } from 'assets/theme/typography';
import styled from 'styled-components';
import { checkURL } from 'lib/helpers/checkURL';
import ToggleButton from '@mui/material/ToggleButton';
import Theme from 'assets/theme/class/theme';
import bg from 'assets/images/features-illustration.svg';

interface CustomColor {
  bgColor?: string;
}

interface ITheme {
  themeStyles: Theme;
}

export const AppWrapper = styled.div<CustomColor>`
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: ${fontSizes.regular};
  ${!checkURL() &&
  `background: linear-gradient(#2e407e, #21202c) no-repeat #21202c;
  background-color: black;`};
  background-color: ${({ bgColor }) => bgColor || 'black'};
  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  ${font.heading};
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: ${fontWeights.bold};
  }
  h1 {
    font-size: 60px;
  }
  h2 {
    font-size: 24px;
  }
  h3 {
    font-size: 16px;
  }
  h4 {
    font-size: 14px;
  }
  h5 {
    font-size: 12px;
  }
  ${font.body};
`;

export const ViewWrapper = styled.main`
  flex: 1;
`;

export const ImageBackground = styled.img`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  object-fit: cover;
  filter: brightness(0.5);
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
`;

export const AppLogoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VIPContainer = styled.div<ITheme>`
  display: flex;
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
  gap: 8px;
`;

export const VIPButton = styled(ToggleButton)`
  &.MuiToggleButton-root {
    width: 100px;
    height: 25px;
    border-radius: 20px;
    font-size: 11px;
  }
`;
