import styled from 'styled-components';
interface Theme {
  themeColors: any;
}

export const HomeContainer = styled.div<Theme>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;

  input {
    display: flex;
    height: 36px;
    width: 100%;
    border: none;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 24px;
    background-color: transparent;
    border: 1px solid ${({ themeColors }) => themeColors?.inputBorder || '#FFFFFF'};
    border-radius: 50px;
    color: ${({ themeColors }) => themeColors?.primary || '#FFFFFF'};
  }
`;
