import { RootStateOrAny, useSelector } from 'react-redux';
import CryptocurrencyButton from 'components/Buttons/CryptocurrencyButton';
import loadingSpinner from 'assets/lottie-animations/loading-spinner.json';
import { CryptocurrencyContainer, LoadingLottieAnimation } from './styled';
import { cryptoImagesLookup, cryptoNamesLookup } from 'lib/lookups/crypto.lookup';

type Props = {
  onClick: any;
};

const Cryptocurrency = (props: Props) => {
  const { onClick } = props;
  const cryptocurrencyPricing: any[] = useSelector(
    (state: RootStateOrAny) => state?.app?.charge?.pricing
  );

  const clickCurrency = async (c: any) => {
    onClick(c);
  };

  return (
    <>
      {cryptocurrencyPricing ? (
        <CryptocurrencyContainer>
          {Object.keys(cryptocurrencyPricing)
            .sort()
            .map((key, i) => (
              <CryptocurrencyButton
                key={i}
                text={cryptoNamesLookup[key]}
                icon={cryptoImagesLookup[key]}
                onClick={() => clickCurrency(key)}
              />
            ))}
        </CryptocurrencyContainer>
      ) : (
        <LoadingLottieAnimation loop animationData={loadingSpinner} />
      )}
    </>
  );
};

export default Cryptocurrency;
