/* eslint-disable react-hooks/exhaustive-deps */
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import { theme } from 'assets/theme/bitpay/style';
import Footer from 'components/Footer';
import MetaTags from 'components/MetaTags';
import SignUpSignIn from 'components/SignUpSignIn';
import { b2cPolicies, loginRequest, passwordResetRequest } from 'index';
import Layout from 'layout';
import ScrollToTop from 'layout/hoc';
import { FC, Suspense, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Routes, useNavigate } from 'react-router';
import { Route } from 'react-router-dom';
import RoutesTracker from 'routes';
import routes from 'routes/bitpay/index.routes';
import { CLEAR_PASSCODE_LOCK, SET_THEME } from 'store/actions';
import { AppWrapper, ViewWrapper } from 'styled';
import { CustomNavigationClient } from 'utils/NavigationClient';
import MerchantIdWrapper from 'views/BitPay/MerchantIdWrapper';
import { VIPButton, VIPContainer } from 'views/BitPay/PointOfSale/styled';

const App: FC<{ pca: PublicClientApplication }> = ({ pca }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [routerRoutes, setRouterRoutes] = useState<any>();
  const [merchantView, setMerchantView] = useState<boolean>(false);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);

  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  useEffect(() => {
    setRouterRoutes(routes);
    dispatch({ type: SET_THEME, payload: theme });
  }, []);

  useEffect(() => {
    const callbackId = pca.addEventCallback((event: any) => {
      if (
        (event.eventType === EventType.LOGIN_SUCCESS ||
          event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) &&
        event.payload.account
      ) {
        if (compareIssuingPolicy(event.payload.idTokenClaims, b2cPolicies.names.forgotPassword)) {
          pca.loginRedirect(loginRequest);
        }
      }

      if (event.eventType === EventType.LOGIN_FAILURE) {
        // Check for forgot password error
        // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
        if (event.error && event.error.errorMessage.includes('AADB2C90118')) {
          pca.loginRedirect(passwordResetRequest);
        }

        if (event.error && event.error.errorMessage.includes('AADB2C90091')) {
          pca.loginRedirect(loginRequest);
        }
      }
    });

    return () => {
      if (callbackId) {
        pca.removeEventCallback(callbackId);
      }
    };
    // eslint-disable-next-line
  }, [pca]);

  const toggleMerchant: (val: boolean) => void = (val) => {
    setMerchantView(val);
    // If switching to merchant lock the cashier
    if (val) {
      dispatch({ type: CLEAR_PASSCODE_LOCK });
      navigate(`/${merchantId}/admin`);
    }
    // If switching from merchant logout the merchant
    if (!val) {
      pca.logoutRedirect({
        postLogoutRedirectUri: `/${merchantId}/`
      });
    }
  };

  const compareIssuingPolicy: (idTokenClaims: any, policyToCompare: any) => boolean = (
    idTokenClaims: any,
    policyToCompare: any
  ) => {
    let tfpMatches = idTokenClaims['tfp'] === policyToCompare.toLowerCase();
    let acrMatches = idTokenClaims['acr'] === policyToCompare.toLowerCase();
    return tfpMatches || acrMatches;
  };

  return (
    <AppWrapper>
      <MetaTags />

      <RoutesTracker>
        {/* {logo && <Header logo={logo} />} */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '2rem 2rem 0 2rem'
          }}
        >
          <span>
            {!window.location.pathname.includes('lock') &&
              !window.location.pathname.includes('home') && (
                <KeyboardArrowLeft
                  fontSize="large"
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(-1)}
                />
              )}
          </span>
          <VIPContainer>
            <VIPButton
              onChange={() => toggleMerchant(false)}
              value="Cashier"
              style={{
                backgroundColor: !merchantView ? '#FFFFFF' : 'transparent',
                color: !merchantView ? '#000000' : '#ffffff',
                borderColor: '#ffffff'
              }}
            >
              Cashier
            </VIPButton>
            <VIPButton
              onChange={() => toggleMerchant(true)}
              value="Admin"
              style={{
                backgroundColor: merchantView ? '#FFFFFF' : 'transparent',
                color: merchantView ? '#000000' : '#ffffff',
                borderColor: '#ffffff'
              }}
            >
              Admin
            </VIPButton>
          </VIPContainer>
        </div>
        <ViewWrapper>
          <Layout>
            <ScrollToTop />
            <MsalProvider instance={pca}>
              <Suspense fallback={<h1>Loading...</h1>}>
                <Routes>
                  <Route path="/signupsignin" element={<SignUpSignIn />} />
                  <Route path="/:merchantId" element={<MerchantIdWrapper />}>
                    {routerRoutes &&
                      routerRoutes.map(
                        ({ path, component }: { path: any; component: any }, i: number) => (
                          <Route key={i} path={path} element={component} />
                        )
                      )}
                  </Route>
                </Routes>
              </Suspense>
            </MsalProvider>
          </Layout>
        </ViewWrapper>
        <Footer />
      </RoutesTracker>
    </AppWrapper>
  );
};

export default App;
