import styled from 'styled-components';
import { fontSizes } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';

interface IButtonPropsType {
  background: boolean;
  color: string;
}

interface Theme extends IButtonPropsType {
  themeColors: any;
}

export const KeyboardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: flex-end;
  justify-items: center;
  align-items: center;
  gap: 1.5rem;
  margin-top: 2rem;
`;

export const KeyboardButton = styled.div<Theme>`
  border: 1px solid ${({ themeColors }) => themeColors?.primary || '#FFFFFF'};
  border-radius: 50%;
  font-size: ${fontSizes.xxlarge};
  color: ${({themeColors}) => themeColors?.primary};
  width: 17vw;
  height: 17vw;
  max-width: 100px;
  max-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid
    ${(props) =>
      props.color ? props.color : ({ themeColors }) => themeColors?.primary || '#FFFFFF'};
  ${(props) =>
    props.background
      ? 'background-color: rgba(255, 255, 255, 0.15); border: 1px solid transparent;'
      : 'background-color: transparent;'}
`;
export const IconContainer = styled.div`
  border: 3px solid white;
  border-radius: 50%;
  width: ${isMobile() ? '8vw' : '5vw'};
  height: ${isMobile() ? '8vw' : '5vw'};
  max-width: 60px;
  max-height: 60px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
