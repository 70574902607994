import styled from 'styled-components';
import { ButtonProps } from 'components/Buttons/button.interface';
import { fontSizes } from 'assets/theme/typography';

export const CryptocurrencyButtonContainer = styled.div<ButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  font-size: ${fontSizes.small};

  img {
    width: 40px;
  }
`;
