import { HomeContainer } from './styled';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useSelector } from 'react-redux';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { FC } from 'react';

const Home: FC = () => {
  const navigate = useNavigate();
  const theme = useSelector((state: RootStateOrAny) => state.app.theme);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);
  return (
    <HomeContainer themeColors={theme}>
      <PrimaryButton
        borderColor={theme.primary}
        textColor={theme.primary}
        text="CREATE CHARGE"
        clickHandler={() => navigate(`/${merchantId}/charge`)}
      />
      <PrimaryButton
        borderColor={theme.primary}
        textColor={theme.primary}
        text="POINT OF SALE"
        clickHandler={() => navigate(`/${merchantId}/pos`)}
      />
      <PrimaryButton
        borderColor={theme.primary}
        textColor={theme.primary}
        text="PAYMENTS"
        clickHandler={() => navigate(`/${merchantId}/payments`)}
      />
    </HomeContainer>
  );
};

export default Home;
