import React, { FC } from 'react';
import { NavButtonContainer } from './styled';

export interface NavButtonProps {
  text?: string;
  icon: JSX.Element;
}

const NavButton: FC<NavButtonProps> = ({ text, icon }) => {
  return (
    <NavButtonContainer>
      {icon}
      {text}
    </NavButtonContainer>
  );
};

export default NavButton;
