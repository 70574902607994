import { typography } from 'assets/theme/typography';
import styled from 'styled-components';
import ToggleButton from '@mui/material/ToggleButton';
import { isMobile } from 'lib/helpers/mobile';

export const POSContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${isMobile() ? 0 : '24px'};
  position: absolute;
  width: 100%;
  height: ${isMobile() ? 'calc(100% - 15rem)' : 'calc(100% - 285px)'};
  top: ${isMobile() ? '9rem' : '200px'};
  overflow: auto;
  padding: 0 ${isMobile() ? 0 : '1.25rem'};
`;

export const POSItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding-bottom: 2rem;
`;

export const POSCartContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  padding-bottom: 2rem;
`;

export const POSCart = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  height: 35rem;
  min-height: 100px;
  background-color: #00000080;
  border-radius: 12px 12px 0 0;
  padding: 16px 24px;
  box-sizing: border-box;
  overflow-y: auto;
`;

export const POSCartItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const POSCartTotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #00000080;
  border-radius: 0 0 12px 12px;
  margin-bottom: 16px;
  padding: 16px 24px;
`;

export const POSCartTotalTitle = styled.h3`
  margin: 0;
  font-size: ${isMobile() ? '12px' : 'inherit'};
`;

export const POSCartTotalValue = styled.p`
  margin: 0;
  ${typography.bodyBold};
  font-size: ${isMobile() ? '12px' : 'inherit'};
`;

export const POSCartActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const POSCartItemName = styled.p`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-size: ${isMobile() ? '12px' : 'inherit'};
`;

export const POSCartItemQuantity = styled.p`
  width: 20px;
  margin: 0;
  font-size: ${isMobile() ? '12px' : 'inherit'};
`;

export const POSCartItemPrice = styled.p`
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 80px;
  justify-content: center;
  align-items: flex-end;
  font-size: ${isMobile() ? '12px' : 'inherit'};
`;

export const POSCartItemRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

export const POSCartRowRightContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex: 0.3;
  align-items: center;
  justify-content: flex-end;
`;

export const VIPContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 8px;
`;

export const VIPButtonActive = styled(ToggleButton)`
  &.MuiToggleButton-root {
    background-color: #ffffff;
    border-color: #ffffff;
    color: #000000;
    width: 100px;
    height: 25px;
    border-radius: 20px;
    font-size: 11px;
    &.Mui-selected {
      color: #ffffff;
      background-color: 'transparent';
    }
    &:hover {
      color: #ffffff;
      background-color: 'transparent';
    }
  }
`;

export const VIPButton = styled(ToggleButton)`
  &.MuiToggleButton-root {
    width: 100px;
    height: 25px;
    border-radius: 20px;
    font-size: 11px;
  }
`;
