import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { StateContainer } from '../styled';
import { RootStateOrAny, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import CancelIcon from '@mui/icons-material/Cancel';

interface ChargeStateProps {
  status?: string;
}

const ChargeState: FC<ChargeStateProps> = ({ status }) => {
  const navigate = useNavigate();
  const theme = useSelector((state: RootStateOrAny) => state?.app?.theme);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);

  const renderStatus: () => JSX.Element = () => {
    switch (status) {
      case 'CANCELED':
        return (
          <StateContainer onClick={() => navigate(`/${merchantId}/pos`)}>
            <CancelIcon sx={{ color: theme.error, fontSize: 200 }} />
            Payment has been cancelled.
          </StateContainer>
        );
      case 'UNRESOLVED':
        return (
          <StateContainer onClick={() => navigate(`/${merchantId}/pos`)}>
            <FmdBadIcon sx={{ color: theme.warning, fontSize: 200 }} />
            Payment has been received but is not correct, please manually check whether the payment
            has been made.
          </StateContainer>
        );
      case 'REFUND PENDING':
        return (
          <StateContainer onClick={() => navigate(`/${merchantId}/pos`)}>
            <CheckCircleIcon sx={{ color: theme.success, fontSize: 200 }} />
            Payment has been made and a refund is currently pending.
          </StateContainer>
        );
      case 'EXPIRED':
        return (
          <StateContainer onClick={() => navigate(`/${merchantId}/pos`)}>
            <FmdBadIcon sx={{ color: theme.warning, fontSize: 200 }} />
            Cannot complete payment. Charge has expired.
          </StateContainer>
        );
      default:
        return (
          <StateContainer onClick={() => navigate(`/${merchantId}/pos`)}>
            <CheckCircleIcon sx={{ color: theme.success, fontSize: 200 }} />
            Thank you for your payment.
          </StateContainer>
        );
    }
  };

  return renderStatus();
};

export default ChargeState;
