import Base from 'api/base';
import Axios from 'axios';

export default class AdminProductApi extends Base {
  public getProducts: (token: string) => Promise<any> = async (token: string) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await Axios.get(`${window.API_PATH}products`, headers);
    return data;
  };
  public getProductCategories: (token: string) => Promise<any> = async (token: string) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await Axios.get(`${window.API_PATH}productcategories`, headers);
    return data;
  };
  public getProduct: (id: string, token: string) => Promise<any> = async (id, token: string) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await Axios.get(`${window.API_PATH}products/${id}`, headers);
    return data;
  };
  public putProduct: (id: string, payload: any, token: string) => Promise<any> = async (
    id,
    payload,
    token: string
  ) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await Axios.put(`${window.API_PATH}products/${id}`, payload, headers);
    return data;
  };
  public postProductCategory: (payload: any, token: string) => Promise<any> = async (
    payload,
    token: string
  ) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await Axios.post(
      `${window.API_PATH}productCategories`,
      [{ categoryName: payload }],
      headers
    );
    return data;
  };
  public postProduct: (payload: any, token: string) => Promise<any> = async (
    payload,
    token: string
  ) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await Axios.post(`${window.API_PATH}products`, [payload], headers);
    return data;
  };
}
