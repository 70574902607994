import { FC, useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router';
import Chip from '@mui/material/Chip';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AdminCashiersApi from 'api/admin/cashiers.api';
import { PublicClientApplication } from '@azure/msal-browser';
import { authResult } from 'config/authConfig';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useSelector, RootStateOrAny } from 'react-redux';

const CashierList: FC = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const adminCashiersApi = new AdminCashiersApi('');

  const navigate = useNavigate();
  const [cashiers, setCashiers] = useState<any[]>([]);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);

  useEffect(() => {
    getCashiers();
  }, []);

  const getCashiers: () => Promise<void> = async () => {
    const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
    setCashiers(await adminCashiersApi.getCashiers(token));
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '50rem' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer'
        }}
      >
        <KeyboardBackspaceIcon
          fontSize="large"
          style={{ cursor: 'pointer', marginLeft: '3rem' }}
          onClick={() => navigate(`/${merchantId}/admin`)}
        />
        <AddCircleOutlineIcon
          style={{ color: 'white', cursor: 'pointer', marginRight: '3rem' }}
          fontSize="large"
          onClick={() => navigate(`/${merchantId}/admin/cashiers/add`)}
        />
      </div>
      <List style={{ height: '40rem', overflowY: 'auto', width: '100%', padding: '0 2rem' }}>
        {cashiers.map((p) => (
          <ListItem
            style={{
              flex: 'row',
              width: '100%',
              height: '4rem',
              alignItems: 'center',
              justifyContent: 'space-between',
              borderBottom: '1px solid grey',
              cursor: 'pointer'
            }}
            key={p.id}
            onClick={() => navigate(`/${merchantId}/admin/cashiers/${p.id}`)}
          >
            {' '}
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '2rem',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              {p.isActive ? (
                <Chip
                  label="Active"
                  color="success"
                  size="small"
                  style={{ width: '70px', cursor: 'pointer' }}
                />
              ) : (
                <Chip
                  label="Inactive"
                  style={{ backgroundColor: 'grey', width: '70px', cursor: 'pointer' }}
                  size="small"
                />
              )}
              <span style={{ fontWeight: 600 }}>
                {p.firstName} {p.surname}
              </span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
              <EditIcon
                style={{ color: 'grey', cursor: 'pointer' }}
                fontSize="small"
                onClick={() => navigate(`/${merchantId}/admin/cashiers/${p.id}`)}
              />
              {/* <ClearIcon style={{ color: 'red', cursor: 'pointer' }} fontSize="small" /> */}
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default CashierList;
