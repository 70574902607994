import { FC } from 'react';
import { PrimaryButtonStyled } from './styled';
import { ButtonProps } from 'components/Buttons/button.interface';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const PrimaryButton: FC<ButtonProps> = ({
  text = '',
  clickHandler = () => {},
  textColor,
  borderColor,
  disabled = false,
  fullWidth
}) => (
  <PrimaryButtonStyled
    onClick={clickHandler}
    textColor={textColor}
    borderColor={borderColor}
    disabled={disabled}
    fullWidth={fullWidth}
  >
    {text}
    <ArrowForwardIosOutlinedIcon />
  </PrimaryButtonStyled>
);

export default PrimaryButton;
