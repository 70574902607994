import { Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import Theme from 'assets/theme/class/theme';
import { PrimaryButton } from 'components/Buttons';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AdminCashiersApi from 'api/admin/cashiers.api';
import { PublicClientApplication } from '@azure/msal-browser';
import { authResult } from 'config/authConfig';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

type TCashier = {
  firstName: string;
  id: string;
  isActive: boolean;
  surname: string;
  pin: string;
};

const CashierDetail: FC = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const adminCashierApi = new AdminCashiersApi('');
  const navigate = useNavigate();
  const params = useParams();
  const theme: Theme = useSelector((state: RootStateOrAny) => state.app.theme);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);
  const [cashier, setCashier] = useState<TCashier>({
    firstName: '',
    id: '',
    isActive: false,
    surname: '',
    pin: ''
  });
  const [error, setError] = useState<string>('');

  useEffect(() => {
    retrieveCashier();
  }, []);

  const retrieveCashier: () => void = async () => {
    const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
    const id = params['id'];
    if (!id) return;
    const res = await adminCashierApi.getCashier(id, token);

    setCashier(res);
  };

  const saveCashier: () => void = async () => {
    const token = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
    const id = params['id'];
    if (!id) return;
    try {
      await adminCashierApi.putCashier(id, cashier, token);

      navigate(`/${merchantId}/admin/cashiers`);
    } catch(e: any) {
      if (e.response.status == 400) {
        setError('Cashier already exists with that pin')
      } else {
        setError('An error occurred requesting your withdrawal, please try again later')
      }
    }
  };

  return cashier ? (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '30rem' }}>
      <KeyboardBackspaceIcon
        fontSize="large"
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/${merchantId}/admin/cashiers`)}
      />
      <h2>
        {cashier?.firstName || ''} {cashier?.surname || ''}
      </h2>
      <TextField
        disabled
        style={{ backgroundColor: 'white' }}
        label="First name"
        variant="filled"
        value={cashier?.firstName}
        onChange={(e) => {
          e.preventDefault();
          const copy = { ...cashier };
          copy.firstName = e.target.value;
          setCashier(copy);
        }}
      />
      <TextField
        disabled
        style={{ backgroundColor: 'white' }}
        label="Surname"
        variant="filled"
        value={cashier?.surname}
        onChange={(e) => {
          e.preventDefault();
          const copy = { ...cashier };
          copy.surname = e.target.value;
          setCashier(copy);
        }}
      />
      <TextField
        style={{ backgroundColor: 'white' }}
        label="PIN"
        variant="filled"
        type="number"
        value={cashier?.pin}
        onChange={(e) => {
          e.preventDefault();
          const copy = { ...cashier };
          copy.pin = e.target.value;
          setCashier(copy);
        }}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              sx={{
                '.MuiSwitch-track': {
                  backgroundColor: 'white'
                }
              }}
              color="success"
              checked={cashier?.isActive}
              onChange={(e: any) => {
                e.preventDefault();
                const copy = { ...cashier };
                copy.isActive = e.target.checked;
                setCashier(copy);
              }}
            />
          }
          label="Is active?"
        />
      </FormGroup>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'end' }}>
        <PrimaryButton
          borderColor={theme.primary}
          textColor={theme.primary}
          text="Save"
          fullWidth
          clickHandler={() => saveCashier()}
        />
      </div>
      
      { error && <p>{error}</p> }
    </div>
  ) : (
    <p>Loading...</p>
  );
};

export default CashierDetail;
