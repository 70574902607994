import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { configureStore } from 'store';
import { BrowserRouter as Router } from 'react-router-dom';
import { EventType, LogLevel, PublicClientApplication } from '@azure/msal-browser';

import App from './App';
// import 'assets/css/styles.css';
import 'translations/i18n';
import 'assets/fonts/stylesheet.css';
require('assets/css/styles.css');
// import reportWebVitals from './reportWebVitals';

const store = configureStore();
const persistor = persistStore(store);

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0;

export const b2cPolicies = {
  names: {
    signIn: 'B2C_1_signin',
    signUp: 'B2C_1_signup',
    forgotPassword: 'B2C_1_password_reset'
  },
  authorities: {
    signIn: {
      authority: `https://tlccbitpayajb2c.b2clogin.com/tlccbitpayajb2c.onmicrosoft.com/B2C_1_signin`
    },
    signUp: {
      authority: `https://tlccbitpayajb2c.b2clogin.com/tlccbitpayajb2c.onmicrosoft.com/B2C_1_signup`
    },
    forgotPassword: {
      authority: `https://tlccbitpayajb2c.b2clogin.com/tlccbitpayajb2c.onmicrosoft.com/B2C_1_password_reset`
    }
  },
  authorityDomain: `https://tlccbitpayajb2c.b2clogin.com`
};

export const msalConfig = {
  auth: {
    clientId: 'dbae9520-19bc-4c8e-a09a-5b2fdfae1c45',
    authority: b2cPolicies.authorities.signUp.authority,
    knownAuthorities: ['tlccbitpayajb2c.b2clogin.com'],
    redirectUri: '/',
    postLogoutRedirectUri: '/'
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
    loggerOptions: {
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      }
    }
  }
};

export const loginRequest = {
  authority: b2cPolicies.authorities.signIn.authority,
  scopes: [`https://tlccbitpayajb2c.onmicrosoft.com/b4e74c88-fcd7-4d8c-9e02-3f78aba5bd66/Admin`]
};

export const signUpRequest = {
  authority: b2cPolicies.authorities.signUp.authority,
  scopes: [`https://tlccbitpayajb2c.onmicrosoft.com/b4e74c88-fcd7-4d8c-9e02-3f78aba5bd66/Admin`]
};

export const passwordResetRequest = {
  authority: b2cPolicies.authorities.forgotPassword.authority,
  scopes: [`https://tlccbitpayajb2c.onmicrosoft.com/b4e74c88-fcd7-4d8c-9e02-3f78aba5bd66/Admin`]
};

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance
  .initialize()
  .then(() => {
    // Default to using the first account if no account is active on page load
    if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
      // Account selection logic is app dependent. Adjust as needed for different use cases.
      msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
    }

    // Optional - This will update account state if a user signs in from another tab or window
    msalInstance.enableAccountStorageEvents();

    msalInstance.addEventCallback((event: any) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
      }
    });
  })
  .then(() => {
    ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <Router>
              <App pca={msalInstance} />
            </Router>
          </PersistGate>
        </Provider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
