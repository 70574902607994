/* eslint-disable react-hooks/exhaustive-deps */
import { PublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import EditIcon from '@mui/icons-material/Edit';
import ClearIcon from '@mui/icons-material/Clear';
import { Button, Chip } from '@mui/material';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import AdminProductApi from 'api/admin/products.api';
import { ProductCategory } from 'api/interfaces/products.interface';
import loadingStar from 'assets/lottie-animations/loading-star.json';
import { theme } from 'assets/theme/bitpay/style';
import { authResult } from 'config/authConfig';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import _, { uniqueId } from 'lodash';
import Lottie from 'lottie-react';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  ADD_PRODUCT_CART,
  CLEAR_PRODUCT_CART,
  REMOVE_PRODUCT_CART,
  SET_CHARGE_CODE,
  SET_CHARGES
} from 'store/actions';
import {
  POSCart,
  POSCartActionContainer,
  POSCartContainer,
  POSCartItemName,
  POSCartItemPrice,
  POSCartItemQuantity,
  POSCartItemRow,
  POSCartItemsContainer,
  POSCartRowRightContainer,
  POSCartTotalContainer,
  POSCartTotalTitle,
  POSCartTotalValue,
  POSContainer,
  POSItemsContainer
} from './styled';
import { isMobile } from 'lib/helpers/mobile';
import OrderList from 'components/OrderList';
import POSOrderSummaryMobile from 'components/POSOrderSummaryMobile';

export type TCartItem = {
  productId: string;
  name: string;
  amount: number;
  quantity: number;
  currency: string;
  raw: any;
};

const PointOfSale: FC = () => {
  const adminProductApi = new AdminProductApi('');
  const [products, setProducts] = useState<any[]>([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error: any[] = useSelector((state: RootStateOrAny) => state?.app?.error);
  const loading: any[] = useSelector((state: RootStateOrAny) => state?.app?.loading);
  const productList: any[] = useSelector((state: RootStateOrAny) => state?.app?.productList);
  const cart: any[] = useSelector((state: RootStateOrAny) => state?.app?.cart);
  const chargeCurrency: string = useSelector((state: RootStateOrAny) => state?.app?.chargeCurrency);
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);
  const token: string = useSelector((state: RootStateOrAny) => state?.app?.token);

  const addToCart = (product: any) => {
    dispatch({ type: ADD_PRODUCT_CART, payload: product });
  };

  const removeFromCart = (id: string) => {
    dispatch({ type: REMOVE_PRODUCT_CART, payload: id });
  };

  const cancel = () => {
    dispatch({ type: CLEAR_PRODUCT_CART });
    navigate(`/${merchantId}/home`);
  };

  const checkout = () => {
    dispatch({
      type: SET_CHARGE_CODE,
      payload: {
        amount: `${getTotal()}`,
        currency: chargeCurrency,
        name: 'Charge',
        description: 'Charge',
        products: cart.map((p) => {
          return {
            productId: p.id,
            amount: p.amount,
            currency: p.currency
          };
        })
      }
    });
    dispatch({ type: SET_CHARGES });
    navigate(`/${merchantId}/charge-detail`);
  };

  const getTotal = () => {
    const result = cart.reduce((accumulator, obj) => {
      return accumulator + obj.amount;
    }, 0);

    return result;
  };

  useEffect(() => {
    getProducts();
  }, []);

  useEffect(() => {
    if (error) {
      navigate(`/${merchantId}/lock`);
    }
  }, [error, navigate]);

  const mappedCartItems: () => TCartItem[] = () => {
    const groupedCartItems = _.groupBy(cart, 'id');
    return Object.keys(groupedCartItems)
      .map(
        (productId) =>
          ({
            productId: productId,
            name: groupedCartItems[productId][0].name,
            amount: groupedCartItems[productId].reduce((sum, item) => sum + item.amount, 0),
            quantity: groupedCartItems[productId].length,
            currency: groupedCartItems[productId][0].currency,
            raw: groupedCartItems[productId][0]
          } as TCartItem)
      )
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  };

  const getProducts: () => Promise<void> = async () => {
    const [productsRes, categories] = await Promise.all([
      adminProductApi.getProducts(token),
      adminProductApi.getProductCategories(token)
    ]);
    setProducts(productsRes);
    setCategories(categories);
  };

  const renderTree = () => {
    const data = _.groupBy(products, 'productCategoryId');
    return Object.keys(data).map((categoryId) => (
      <TreeItem
        key={categoryId}
        itemId={categoryId}
        label={categories.find((c) => c.id === categoryId)?.categoryName}
        sx={{ '.MuiTreeItem-label': { fontWeight: 600 } }}
      >
        {data[categoryId].length > 0 ? renderChildren(data[categoryId]) : <></>}
      </TreeItem>
    ));
  };

  const renderChildren: (children: any) => JSX.Element = (children) => {
    return children.map((child: any) => (
      <div
        key={uniqueId('child-')}
        style={{
          display: 'flex',
          flex: 'row',
          width: '100%',
          height: '2.5rem',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer',
          paddingRight: '1rem'
        }}
        onClick={() => addToCart(child)}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '0 2rem'
          }}
        >
          {child.isActive ? (
            <Chip color="success" style={{ width: '8px', height: '8px', cursor: 'pointer' }} />
          ) : (
            <Chip
              style={{ backgroundColor: 'grey', width: '8px', height: '8px', cursor: 'pointer' }}
            />
          )}
          <span style={{ fontWeight: 600 }}>{child.name}</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
          <AddIcon style={{ color: theme.success, cursor: 'pointer' }} fontSize="small" />
        </div>
      </div>
    ));
  };

  const actionButtonsMobile = (
    <POSCartActionContainer>
      <Button
        sx={{
          backgroundColor: cart.length === 0 ? '#FFFFFF50 !important' : '#FFFFFF',
          color: '#000000 !important',
          padding: '6px 16px !important',
          margin: '1rem 1rem 0 1rem',
          fontWeight: '600 !important',
          justifyContent: 'center !important',
          alignItems: 'center !important'
        }}
        variant="contained"
        disabled={cart.length === 0}
        onClick={() => checkout()}
      >
        Checkout
      </Button>
      <Button
        variant="outlined"
        onClick={() => cancel()}
        sx={{
          color: '#FFFFFF',
          margin: '8px 1rem 1rem 1rem',
          borderColor: '#FFFFFF',
          padding: '6px 16px !important',
          fontWeight: '600 !important',
          justifyContent: 'center !important',
          alignItems: 'center !important'
        }}
      >
        Cancel
      </Button>
    </POSCartActionContainer>
  );

  return loading ? (
    <Lottie loop animationData={loadingStar} style={{ height: '12rem' }} />
  ) : (
    <POSContainer>
      <POSItemsContainer>
        {isMobile() && (
          <POSOrderSummaryMobile
            actionBtns={actionButtonsMobile}
            count={cart.length}
            itemAdd={(item: any) => addToCart(item)}
            itemRemove={(item: any) => removeFromCart(item)}
          />
        )}
        <SimpleTreeView>{renderTree()}</SimpleTreeView>
        {isMobile() && actionButtonsMobile}
      </POSItemsContainer>
      {!isMobile() && (
        <POSCartContainer>
          <POSCart>
            <POSCartItemsContainer>
              {mappedCartItems().map((item, index) => (
                <POSCartItemRow key={uniqueId(item.productId)}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '8px',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <POSCartItemQuantity>{item.quantity} x </POSCartItemQuantity>
                    <POSCartItemName>{item.name}</POSCartItemName>
                  </div>
                  <POSCartRowRightContainer>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '8px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '50px'
                      }}
                    >
                      <RemoveIcon
                        onClick={() => removeFromCart(item.productId)}
                        style={{ color: 'red' }}
                        fontSize="small"
                      />
                      <AddIcon
                        onClick={() => addToCart(item.raw)}
                        style={{ color: 'green' }}
                        fontSize="small"
                      />
                    </div>
                    <POSCartItemPrice>
                      {chargeCurrency ? CurrencySymbolsLookUp[chargeCurrency] : ''}
                      {formatNumber(item.amount, 2)}
                    </POSCartItemPrice>
                  </POSCartRowRightContainer>
                </POSCartItemRow>
              ))}
            </POSCartItemsContainer>
          </POSCart>
          <POSCartTotalContainer>
            <POSCartTotalTitle>Total</POSCartTotalTitle>
            <POSCartTotalValue>
              {chargeCurrency ? CurrencySymbolsLookUp[chargeCurrency] : ''}
              {formatNumber(getTotal(), 2)}
            </POSCartTotalValue>
          </POSCartTotalContainer>
          <POSCartActionContainer>
            <Button
              style={{
                backgroundColor: cart.length === 0 ? '#FFFFFF50' : '#FFFFFF',
                color: '#000000'
              }}
              variant="contained"
              disabled={cart.length === 0}
              onClick={() => checkout()}
            >
              Checkout
            </Button>
            <Button
              variant="outlined"
              onClick={() => cancel()}
              style={{ color: '#FFFFFF', borderColor: '#FFFFFF' }}
            >
              Cancel
            </Button>
          </POSCartActionContainer>
        </POSCartContainer>
      )}
    </POSContainer>
  );
};

export default PointOfSale;
