import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { appReducer } from './app';

export interface ReducerAction {
  type: string;
  payload: any;
}

export interface StateBase {
  error: string;
  loading: boolean;
}

export const createRouteReducer = (history: History) =>
  combineReducers({
    router: connectRouter(history),
    app: appReducer
  });
