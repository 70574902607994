import styled from 'styled-components';

interface Theme {
  themeColors: any;
}

export const NavigationContainer = styled.div<Theme>`
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  border-top: 1px solid ${({ themeColors }) => themeColors?.footerBorder || '#FFFFFF'};
  background-color: ${({ themeColors }) => themeColors?.footerBackground || '#FFFFFF'};
  width: 100%;

  ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    max-width: 414px;

    li {
      padding-top: 0.5rem;
      padding-bottom: 0.8rem;

      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }

      a {
        text-decoration: none;
        color: white;
      }
    }
  }
`;
