import Base from 'api/base';
import Axios from 'axios';

export default class AdminBalancesApi extends Base {
  public getBalances: (token: string) => Promise<any> = async (token: string) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await Axios.get(
      `${window.API_PATH}balances`,
      headers
    );
    return data;
  };
}
