import { FC } from 'react';
import { NotFoundContainer } from './styled';

const NotFoundPage: FC = () => {
  return (
    <NotFoundContainer>
      <h5>Page not found !</h5>
    </NotFoundContainer>
  );
};

export default NotFoundPage;
