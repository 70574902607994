import React from 'react';
import Navigation from 'components/Navigation';
import { useLocation } from 'react-router-dom';
import { FooterContainer } from './styled';

type Props = {};

const Footer = (props: Props) => {
  const location = useLocation();
  return (
    <FooterContainer>
      {location.pathname !== '/' && !location.pathname.includes('admin') && <Navigation />}
    </FooterContainer>
  );
};

export default Footer;
