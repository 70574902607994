import { FC } from 'react';

interface RoutesTrackerProps {
  children?: any;
}

const RoutesTracker: FC<RoutesTrackerProps> = ({ children }) => {

  return <>{children}</>;
};

export default RoutesTracker;
