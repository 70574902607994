import Base from 'api/base';
import Axios from 'axios';

export default class AdminCashiersApi extends Base {
  public getCashiers: (token: string) => Promise<any> = async (token: string) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await Axios.get(
      `${window.API_PATH}cashiers`,
      headers
    );
    return data;
  };
  public getCashier: (id: string, token: string) => Promise<any> = async (id, token: string) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await Axios.get(
      `${window.API_PATH}cashiers/${id}`,
      headers
    );
    return data;
  };
  public putCashier: (id: string, payload: any, token: string) => Promise<any> = async (id, payload, token: string) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await Axios.put(
      `${window.API_PATH}cashiers/${id}`,
      payload,
      headers
    );
    return data;
  };
  public postCashier: (payload: any, token: string) => Promise<any> = async (payload, token: string) => {
    const headers = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
    const { data } = await Axios.post(
      `${window.API_PATH}cashiers`,
      payload,
      headers
    );
    return data;
  };
}
