import { combineEpics, Epic, ofType } from 'redux-observable';
import { catchError, from, map, mergeMap, of, switchMap } from 'rxjs';
import {
  verifyPasscode
 } from 'api/charge/auth.api';
import { 
  getCharge,
  getCryptocurrency,
  getCharges,
  getCryptocurrencyAddress
 } from 'api/charge/charge.api';
 import { 
   getProducts
  } from 'api/charge/product.api';

import {
  SET_CHARGES,
  SET_CHARGES_FAILURE,
  SET_CHARGES_SUCCESS,
  SET_CRYPTOCURRENCY,
  SET_CRYPTOCURRENCY_FAILURE,
  SET_CRYPTOCURRENCY_SUCCESS,
  SET_CHARGE_LIST,
  SET_CHARGE_LIST_FAILURE,
  SET_CHARGE_LIST_SUCCESS,
  SET_PASSCODE_LOCK,
  SET_PASSCODE_LOCK_FAILURE,
  SET_PASSCODE_LOCK_SUCCESS,
  SET_PRODUCT_LIST,
  SET_PRODUCT_LIST_SUCCESS,
  SET_PRODUCT_LIST_FAILURE,
  SET_ADDRESS,
  SET_ADDRESS_FAILURE,
  SET_ADDRESS_SUCCESS
  } from 'store/actions';

//===================================================
//                      API CALLS
//===================================================

const getChargeAPI = async (state: any) => {
  return await getCharge(state.app.newChargeBody, state.app.token);
};

const getCryptocurrencyListAPI = async (state: any) => {
  return await getCryptocurrency(state.app.chargeCode, state.app.token);
};

const getCryptocurrencyAddressAPI = async (state: any) => {
  return await getCryptocurrencyAddress(state.app.chargeCode, state.app.selectedCrypto, state.app.token);
};

const getChargesAPI = async (state: any) => {
  return await getCharges(state.app.token);
};

const verifyPasscodeAPI = async (state: any) => {
  return await verifyPasscode(state.app.passcode, state.app.merchantId);
};

const getProductsAPI = async (state: any) => {
  return await getProducts(state.app.token);
};



//===================================================
//                      EPICS
//===================================================
const fetchChargeEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(SET_CHARGES),
    switchMap(() =>
      from(getChargeAPI(state$.value)).pipe(
        map((payload) => ({ type: SET_CHARGES_SUCCESS, payload })),
        catchError((error) => of({ type: SET_CHARGES_FAILURE, payload: error.message }))
      )
    )
  );

const fetchCryptocurrencyListEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(SET_CRYPTOCURRENCY),
    switchMap(() =>
      from(getCryptocurrencyListAPI(state$.value)).pipe(
        map((payload) => ({ type: SET_CRYPTOCURRENCY_SUCCESS, payload })),
        catchError((error) => of({ type: SET_CRYPTOCURRENCY_FAILURE, payload: error.message }))
      )
    )
  );

const fetchCryptocurrencyAddressEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(SET_ADDRESS),
    switchMap(() =>
      from(getCryptocurrencyAddressAPI(state$.value)).pipe(
        map((payload) => ({ type: SET_ADDRESS_SUCCESS, payload })),
        catchError((error) => of({ type: SET_ADDRESS_FAILURE, payload: error.message }))
      )
    )
  );

const fetchChargeListEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(SET_CHARGE_LIST),
    switchMap(() =>
      from(getChargesAPI(state$.value)).pipe(
        map((payload) => ({ type: SET_CHARGE_LIST_SUCCESS, payload })),
        catchError((error) => of({ type: SET_CHARGE_LIST_FAILURE, payload: error.message }))
      )
    )
  );

const verifyPasscodeEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(SET_PASSCODE_LOCK),
    switchMap(() =>
      from(verifyPasscodeAPI(state$.value)).pipe(
        map((payload) => ({ type: SET_PASSCODE_LOCK_SUCCESS, payload })),
        catchError((error) => of({ type: SET_PASSCODE_LOCK_FAILURE, payload: error.message }))
      )
    )
  );

const fetchProductListEpic$: Epic = (action$, state$) =>
  action$.pipe(
    ofType(SET_PRODUCT_LIST),
    switchMap(() =>
      from(getProductsAPI(state$.value)).pipe(
        map((payload) => ({ type: SET_PRODUCT_LIST_SUCCESS, payload })),
        catchError((error) => of({ type: SET_PRODUCT_LIST_FAILURE, payload: error.message }))
      )
    )
  );

export default combineEpics(fetchChargeEpic$, fetchCryptocurrencyListEpic$, fetchCryptocurrencyAddressEpic$, fetchChargeListEpic$, verifyPasscodeEpic$, fetchProductListEpic$);
