import { PublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import AdminProductApi from 'api/admin/products.api';
import { ProductCategory } from 'api/interfaces/products.interface';
import { authResult } from 'config/authConfig';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const ProductList: FC = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const adminProductApi = new AdminProductApi('');
  const navigate = useNavigate();
  const [products, setProducts] = useState<any[]>([]);
  const [categories, setCategories] = useState<ProductCategory[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (id: number) => {
    setAnchorEl(null);
    handleReroute(id);
  };

  useEffect(() => {
    getProducts();
  }, []);

  const getProducts: () => Promise<void> = async () => {
    const token = await authResult(
      instance as PublicClientApplication,
      isAuthenticated,
      inProgress
    );
    const [productsRes, categories] = await Promise.all([
      adminProductApi.getProducts(token),
      adminProductApi.getProductCategories(token)
    ]);
    setProducts(productsRes);
    setCategories(categories);
  };

  const handleReroute: (id: number) => void = (id: number) => {
    switch (id) {
      case 1:
        navigate(`/${merchantId}/admin/product-categories/add`);
        break;
      case 2:
        navigate(`/${merchantId}/admin/products/add`);
        break;
      default:
        return;
    }
  };

  const renderTree = () => {
    const data = _.groupBy(products, 'productCategoryId');
    return Object.keys(data).map((categoryId) => (
      <TreeItem
        key={categoryId}
        itemId={categoryId}
        label={categories.find((c) => c.id === categoryId)?.categoryName}
      >
        {data[categoryId].length > 0 ? renderChildren(data[categoryId]) : <></>}
      </TreeItem>
    ));
    // return categories.map((c) => <TreeItem itemId={c.id} label={c.categoryName}></TreeItem>);
  };

  const renderChildren: (children: any) => JSX.Element = (children) => {
    return children.map((child: any) => (
      <div
        style={{
          display: 'flex',
          flex: 'row',
          width: '100%',
          height: '2rem',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer'
        }}
        onClick={() => navigate(`/${merchantId}/admin/products/${child.id}`)}
      >
        {' '}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '1rem',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '0 2rem'
          }}
        >
          {child.isActive ? (
            <Chip color="success" style={{ width: '8px', height: '8px', cursor: 'pointer' }} />
          ) : (
            <Chip
              style={{ backgroundColor: 'grey', width: '8px', height: '8px', cursor: 'pointer' }}
            />
          )}
          <span style={{ fontWeight: 600 }}>{child.name}</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
          <EditIcon
            style={{ color: 'grey', cursor: 'pointer' }}
            fontSize="small"
            onClick={() => navigate(`/${merchantId}/admin/products/${child.id}`)}
          />
        </div>
      </div>
    ));
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '100%' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          cursor: 'pointer'
        }}
      >
        <KeyboardBackspaceIcon
          fontSize="large"
          style={{ cursor: 'pointer', marginLeft: '2rem' }}
          onClick={() => navigate(`/${merchantId}/admin`)}
        />
        <div>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <AddCircleOutlineIcon style={{ color: 'white', cursor: 'pointer' }} fontSize="large" />
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
          >
            <MenuItem onClick={() => handleClose(1)}>Add category</MenuItem>
            <MenuItem onClick={() => handleClose(2)}>Add product</MenuItem>
          </Menu>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignSelf: 'center',
          width: '40rem',
          overflowY: 'auto',
          padding: '0 2rem'
        }}
      >
        <Box sx={{ width: '100%', height: '40rem', overflowY: 'auto', padding: '0 2rem' }}>
          <SimpleTreeView>{renderTree()}</SimpleTreeView>
        </Box>
      </div>
      {/* <List style={{ height: '40rem', overflowY: 'auto', width: '100%', padding: '0 2rem' }}>
        {products.length > 0 ? (
          products.map((p) => (
            <ListItem
              style={{
                flex: 'row',
                width: '100%',
                height: '4rem',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '1px solid grey',
                cursor: 'pointer'
              }}
              key={p.id}
              onClick={() => navigate(`/${merchantId}/admin/products/${p.id}`)}
            >
              {' '}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '2rem',
                  alignItems: 'center',
                  cursor: 'pointer'
                }}
              >
                {p.isActive ? (
                  <Chip
                    label="Active"
                    color="success"
                    size="small"
                    style={{ width: '70px', cursor: 'pointer' }}
                  />
                ) : (
                  <Chip
                    label="Inactive"
                    style={{ backgroundColor: 'grey', width: '70px', cursor: 'pointer' }}
                    size="small"
                  />
                )}
                <span style={{ fontWeight: 600 }}>{p.name}</span>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '2rem' }}>
                <EditIcon
                  style={{ color: 'grey', cursor: 'pointer' }}
                  fontSize="small"
                  onClick={() => navigate(`/${merchantId}/admin/products/${p.id}`)}
                />
              </div>
            </ListItem>
          ))
        ) : (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            No products available!
          </div>
        )}
      </List> */}
    </div>
  );
};

export default ProductList;
