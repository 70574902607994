import { PublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import TextField from '@mui/material/TextField';
import AdminProductApi from 'api/admin/products.api';
import Theme from 'assets/theme/class/theme';
import { PrimaryButton } from 'components/Buttons';
import { authResult } from 'config/authConfig';
import { FC, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

const ProductCategoryAdd: FC = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const adminProductsApi = new AdminProductApi('');
  const navigate = useNavigate();
  const theme: Theme = useSelector((state: RootStateOrAny) => state.app.theme);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);

  const [category, setCategory] = useState<string>('');

  const saveCategory: () => void = async () => {
    const token = await authResult(
      instance as PublicClientApplication,
      isAuthenticated,
      inProgress
    );
    await adminProductsApi.postProductCategory(category, token);
    navigate(`/${merchantId}/admin/products`);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '30rem' }}>
      <KeyboardBackspaceIcon
        fontSize="large"
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/${merchantId}/admin/products`)}
      />
      <h2>{category}</h2>
      <TextField
        style={{ backgroundColor: 'white' }}
        label="Category name"
        variant="filled"
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      />
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'end' }}>
        <PrimaryButton
          borderColor={theme.primary}
          textColor={theme.primary}
          text="Add"
          fullWidth
          clickHandler={saveCategory}
        />
      </div>
    </div>
  );
};

export default ProductCategoryAdd;
