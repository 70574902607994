import styled, { css } from 'styled-components';
import { fontSizes } from 'assets/theme/typography';

interface InputPropsType {
  status: boolean;
}
interface Theme extends InputPropsType {
  themeColors: any;
}

export const ChargeContainer = styled.div`
  width: 100%;
  max-width: 414px;
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    width: 100%;
  }
`;

export const InputGroup = styled.div<Theme>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  position: relative;

  input {
    display: flex;
    width: 75%;
    max-width: 300px;
    font-size: ${fontSizes.regular};
    letter-spacing: 1px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 15px 0;
    background-color: transparent;
    border: ${(props) =>
      props.status ? css`1px solid #a04baf` : '1px solid rgba(255, 255, 255, .2)'};
    border-radius: 50px;
    color: ${({ themeColors }) => themeColors?.primary || '#FFFFFF'};
    &::placeholder {
      color: rgba(255, 255, 255, 0.2);
    }
    &:focus-visible,
    &:focus {
      outline: none;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: 1px solid rgba(255, 255, 255, 0.2);
      -webkit-text-fill-color: ${({ themeColors }) => themeColors?.primary || '#FFFFFF'};
      -webkit-box-shadow: 0 0 0px 1000px ${({ themeColors }) => themeColors?.shadow || '#FFFFFF'}
        inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
`;

export const InputIcon = styled.div`
  position: absolute;
  right: 0;
`;

export const PaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 414px;
`;

export const PaymentAmount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  letter-spacing: 1px;

  span {
    font-size: ${fontSizes.xlarge};
  }
`;

export const PaymentCaption = styled.div`
  margin-bottom: 0.5rem;
`;
