import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SportsBarIcon from '@mui/icons-material/SportsBar';
import IconButton from '@mui/material/IconButton';
import { formatNumber } from 'lib/helpers/formatters/numberFormatters';
import { isMobile } from 'lib/helpers/mobile';
import { CurrencySymbolsLookUp } from 'lib/lookups/currencySymbols.lookup';
import _, { uniqueId } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { TCartItem } from 'views/BitPay/PointOfSale/PointOfSale';
import {
  POSCartItemName,
  POSCartItemPrice,
  POSCartItemQuantity,
  POSCartItemRow,
  POSCartRowRightContainer,
  POSCartTotalContainer,
  POSCartTotalTitle,
  POSCartTotalValue
} from 'views/BitPay/PointOfSale/styled';
import {
  OrderItemsContainer,
  POSOrderSummaryMobileButton,
  POSOrderSummaryMobileContainer,
  POSOrderSummaryMobileTitle,
  POSOrderSummaryMobileViewContainer
} from './styled';
import Badge from '@mui/material/Badge';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const POSOrderSummaryMobile: FC<{
  count: number;
  actionBtns: JSX.Element;
  itemAdd: any;
  itemRemove: any;
}> = ({ count = 0, actionBtns, itemAdd, itemRemove }) => {
  const theme = useSelector((state: RootStateOrAny) => state?.app?.theme);
  const cart: any[] = useSelector((state: RootStateOrAny) => state?.app?.cart);
  const chargeCurrency: string = useSelector((state: RootStateOrAny) => state?.app?.chargeCurrency);
  const [showOrder, setShowOrder] = useState<boolean>(false);
  const [items, setItems] = useState<TCartItem[]>([]);

  useEffect(() => {
    mappedCartItems();
  }, [cart]);

  const getTotal = () => {
    const result = cart.reduce((accumulator, obj) => {
      return accumulator + obj.amount;
    }, 0);

    return result;
  };

  const setOrderList: () => void = () => {
    setShowOrder(!showOrder);
  };

  const mappedCartItems: () => void = () => {
    const groupedCartItems = _.groupBy(cart, 'id');
    setItems(
      Object.keys(groupedCartItems)
        .map(
          (productId) =>
            ({
              productId: productId,
              name: groupedCartItems[productId][0].name,
              amount: groupedCartItems[productId].reduce((sum, item) => sum + item.amount, 0),
              quantity: groupedCartItems[productId].length,
              currency: groupedCartItems[productId][0].currency,
              raw: groupedCartItems[productId][0]
            } as TCartItem)
        )
        .sort((a, b) => (a.name > b.name ? 1 : -1))
    );
  };

  const orderListRender: () => JSX.Element = () => (
    <OrderItemsContainer>
      <POSOrderSummaryMobileTitle>ORDER LIST</POSOrderSummaryMobileTitle>
      {items.map((item) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
            borderBottom: '1px solid grey',
            paddingBottom: '8px'
          }}
        >
          <POSCartItemRow key={uniqueId(item.productId)}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <POSCartItemQuantity style={{ width: '30px' }}>
                {item.quantity} x{' '}
              </POSCartItemQuantity>
              <POSCartItemName>{item.name}</POSCartItemName>
            </div>
            <POSCartItemPrice style={{width: '60px'}}>
              {chargeCurrency ? CurrencySymbolsLookUp[chargeCurrency] : ''}
              {formatNumber(item.amount, 2)}
            </POSCartItemPrice>
          </POSCartItemRow>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <RemoveIcon
              onClick={(e) => {
                e.preventDefault();
                itemRemove(item.productId);
              }}
              style={{ color: 'red' }}
              fontSize="small"
            />
            <AddIcon
              onClick={(e) => {
                e.preventDefault();
                itemAdd(item.raw);
              }}
              style={{ color: 'green' }}
              fontSize="small"
            />
            
          </div>
        </div>
      ))}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginTop: '2rem'
        }}
      >
        <POSCartTotalTitle>Total</POSCartTotalTitle>
        <POSCartItemPrice>
          {chargeCurrency ? CurrencySymbolsLookUp[chargeCurrency] : ''}
          {formatNumber(getTotal(), 2)}
        </POSCartItemPrice>
      </div>
      {actionBtns}
    </OrderItemsContainer>
  );

  return (
    <POSOrderSummaryMobileContainer>
      <POSOrderSummaryMobileButton show={!showOrder} onClick={setOrderList}>
        <Badge
          sx={{ '.MuiBadge-badge': { transform: 'translate(-25px, -8px)' } }}
          badgeContent={count}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          color="secondary"
        >
          <SportsBarIcon /> {!isMobile() && 'View Order List'}
        </Badge>
      </POSOrderSummaryMobileButton>
      <POSOrderSummaryMobileViewContainer
        themecolors={theme}
        variant="temporary"
        anchor={'right'}
        open={showOrder}
        onClose={() => {
          setShowOrder(true);
        }}
        ModalProps={{
          keepMounted: true // Better open performance on mobile.
        }}
      >
        <IconButton onClick={setOrderList}>
          <ArrowBackIosNewIcon />
        </IconButton>
        {orderListRender()}
      </POSOrderSummaryMobileViewContainer>
    </POSOrderSummaryMobileContainer>
  );
};

export default POSOrderSummaryMobile;
