import Home from 'views/BitPay/Home';
import Login from 'views/BitPay/Login';
import Charge from 'views/BitPay/Charge';
import Payments from 'views/BitPay/Payments';
import Invoices from 'views/BitPay/Invoices';
import PointOfSale from 'views/BitPay/PointOfSale';
import NotFoundPage from 'views/BitPay/NotFound';
import ChargeDetail from 'views/BitPay/Charge/ChargeDetail';
import AdminHome from 'views/merchant/Home';
import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import ProductList from 'views/merchant/Products/ProductList';
import ProductDetail from 'views/merchant/Products/ProductDetail';
import ProductAdd from 'views/merchant/Products/ProductAdd';
import CashierList from 'views/merchant/Cashiers/CashierList';
import CashierDetail from 'views/merchant/Cashiers/CashierDetail';
import CashierAdd from 'views/merchant/Cashiers/CashierAdd';
import ProductCategoryAdd from 'views/merchant/Products/ProductCategoryAdd';
import BalanceList from 'views/merchant/Balances/BalanceList';

const routes = [
  {
    path: 'lock',
    name: 'Home',
    component: <Login />
  },
  {
    path: '/:merchantId',
    name: 'Home',
    component: <Login />
  },
  {
    path: 'admin',
    name: 'Admin Home',
    component: <AdminHome />
  },
  {
    path: 'admin/products',
    name: 'Product List',
    component: (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <ProductList />
      </MsalAuthenticationTemplate>
    )
  },
  {
    path: 'admin/products/:id',
    name: 'Product Details',
    component: (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <ProductDetail />
      </MsalAuthenticationTemplate>
    )
  },
  {
    path: 'admin/products/add',
    name: 'Product Add',
    component: (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <ProductAdd />
      </MsalAuthenticationTemplate>
    )
  },
  {
    path: 'admin/products-category/add',
    name: 'Product Category Add',
    component: (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <ProductCategoryAdd />
      </MsalAuthenticationTemplate>
    )
  },
  {
    path: 'admin/cashiers',
    name: 'Cashiers List',
    component: (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <CashierList />
      </MsalAuthenticationTemplate>
    )
  },
  {
    path: 'admin/cashiers/:id',
    name: 'Cashiers Details',
    component: (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <CashierDetail />
      </MsalAuthenticationTemplate>
    )
  },
  {
    path: 'admin/cashiers/add',
    name: 'Cashiers Add',
    component: (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <CashierAdd />
      </MsalAuthenticationTemplate>
    )
  },
  {
    path: 'admin/balances',
    name: 'Balances',
    component: (
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        <BalanceList />
      </MsalAuthenticationTemplate>
    )
  },
  {
    path: 'home',
    name: 'Homepage',
    component: <Home />
  },
  {
    path: 'charge',
    name: 'Charge',
    component: <Charge />
  },
  {
    path: 'charge-detail',
    name: 'Charge Detail',
    component: <ChargeDetail />
  },
  {
    path: 'payments',
    name: 'Payments',
    component: <Payments />
  },
  {
    path: 'invoices',
    name: 'Invoices',
    component: <Invoices />
  },
  {
    path: 'pos',
    name: 'Point of Sale',
    component: <PointOfSale />
  },
  {
    path: '*',
    name: 'Not Found',
    component: <NotFoundPage />
  }
];

export default routes;
