import Axios from 'axios';
import { AsyncTask } from 'api/interfaces/asyncTask.interface';


export const getProducts: (
  token: string
) => Promise<any[]> = async (token: string) => {
  let result: any[] = [];
  let skip = 0;
  let yielded: number;
  do {
    const response: any  = await Axios.get<any[]>(
      `${window.API_PATH}products?$top=100&$skip=${skip}&$filter=isActive eq true`, {
        headers: {
          'Authorization': `Bearer ${token}` 
        }
      }
    );
    skip += 100;
    yielded = response.data.length;
    if (yielded > 0) {
      result = [...result, ...response.data];
    }
  } while(yielded > 0)
  
  return result;
};