import Theme from 'assets/theme/class/theme';
import styled from 'styled-components';

interface ITheme {
  themeColors: Theme;
}

export const HomeContainer = styled.div<ITheme>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 100%;
`;
