/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import loadingStar from 'assets/lottie-animations/loading-star.json';
import Lottie from 'lottie-react';
import NewState from 'components/Charge/States/NewState';
import ChargeState from 'components/Charge/States/ChargeState';
import { SET_CRYPTOCURRENCY, SET_ADDRESS, CLEAR_PRODUCT_CART } from 'store/actions';
import OrderList from 'components/OrderList';

const Payments: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error: any[] = useSelector((state: RootStateOrAny) => state?.app?.error);
  const charge: any = useSelector((state: RootStateOrAny) => state?.app?.charge);
  const loading: any = useSelector((state: RootStateOrAny) => state?.app?.loading);
  const code: any[] = useSelector((state: RootStateOrAny) => state?.app?.chargeCode);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);
  const [complete, setComplete] = useState<boolean>(false);

  let pollTimer: NodeJS.Timeout;

  const [addressDetail, setAddressDetail] = useState<string>('');

  useEffect(() => {
    if (error) navigate(`/${merchantId}/lock`);
  }, [error]);

  useEffect(() => {
    if (addressDetail) {
      dispatch({ type: SET_ADDRESS, payload: addressDetail });
    }
  }, [addressDetail]);

  useEffect(() => {
    pollTimer = setTimeout(() => {
      dispatch({ type: SET_CRYPTOCURRENCY, payload: code });
    }, 2000);

    if (['COMPLETED', 'RESOLVED', 'CANCELED', 'CONFIRMED'].includes(charge?.status))
      setComplete(true);

    return () => {
      clearTimeout(pollTimer);
      if (complete) dispatch({ type: CLEAR_PRODUCT_CART });
    };
  }, [charge]);

  const enumStates = {
    NEW: (
      <NewState charge={charge} addressDetail={addressDetail} setAddressDetail={setAddressDetail} />
    ),
    PENDING: <ChargeState status="PENDING" />,
    COMPLETED: <ChargeState status="COMPLETED" />,
    EXPIRED: <ChargeState status="EXPIRED" />,
    UNRESOLVED: <ChargeState status="UNRESOLVED" />,
    RESOLVED: <ChargeState status="RESOLVED" />,
    CANCELED: <ChargeState status="CANCELED" />,
    'REFUND PENDING': <ChargeState status="REFUND PENDING" />,
    REFUNDED: <ChargeState status="REFUNDED" />,
    CONFIRMED: <ChargeState status="CONFIRMED" />
  };

  const chargeRender: () => JSX.Element = () => (
    <>
      {enumStates[charge.status as keyof typeof enumStates]}
      <OrderList />
    </>
  );

  return loading && !charge ? (
    <Lottie loop animationData={loadingStar} style={{ height: '12rem' }} />
  ) : charge ? (
    chargeRender()
  ) : (
    <p>Charge does not exist. </p>
  );
};

export default Payments;
