import styled from 'styled-components';
import { fontSizes } from 'assets/theme/typography';

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 414px;
`;

export const LoginHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1.5rem;

  span {
    font-size: ${fontSizes.small};
    letter-spacing: 1px;
  }
  svg {
    font-size: ${fontSizes.xlarge};
    margin-left: 5px;
  }
`;
