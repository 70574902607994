import styled from 'styled-components';
import { ButtonProps } from 'components/Buttons/button.interface';
import { fontSizes } from 'assets/theme/typography';

export const NavButtonContainer = styled.div<ButtonProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  font-size: ${fontSizes.small};

  img {
    width: 2rem;
  }
`;
