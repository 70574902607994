import styled from 'styled-components';
import { fontSizes } from 'assets/theme/typography';

export const PaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 414px;
  position: absolute;
  top: 170px;
  height: calc(100% - 300px);
  overflow: scroll;
`;

export const PaymentAmount = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  letter-spacing: 1px;
  font-weight: bold;

  span {
    font-size: ${fontSizes.xlarge};
  }
`;

export const PaymentStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  letter-spacing: 1px;

  span {
    font-size: ${fontSizes.xlarge};
  }
`;

export const PaymentDateTime = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30%;
  letter-spacing: 1px;

  span {
    font-size: ${fontSizes.xlarge};
  }
`;

export const PaymentsListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  cursor: pointer;
`;
