import react, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { 
  PaymentsContainer,
  PaymentAmount,
  PaymentsListItem,
  PaymentStatus,
  PaymentDateTime
} from './styled';
import { 
  SET_CHARGE_LIST,
  SET_CRYPTOCURRENCY
} from 'store/actions';
import { formatDateTime } from 'lib/helpers/formatters/datetimeFormatters';

const Payments: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error: any[] = useSelector((state: RootStateOrAny) => state?.app?.error);
  const chargeList: any[] = useSelector((state: RootStateOrAny) => state?.app?.chargeList);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);

  const getCharges =  () => {
    dispatch({ type: SET_CHARGE_LIST });
  }

  useEffect(() => {
    getCharges();
  }, [])

  const chargeClick = (code: string)=> {
    if (code) {
      dispatch({ type: SET_CRYPTOCURRENCY, payload: code });
      navigate(`/${merchantId}/charge-detail`);
    }
  };

  useEffect(() => {
    if (error) {
      navigate(`/${merchantId}/lock`);
    }
  }, [error]);


  return (
    <>
    {chargeList ? 
      <PaymentsContainer>
        {chargeList.map((charge, index) => (
          <PaymentsListItem key={index} onClick={() => chargeClick(charge.id)}>
            <PaymentAmount>{charge.amount} {charge.currency}</PaymentAmount>
            <PaymentStatus>{charge.status}</PaymentStatus>
            <PaymentDateTime>{formatDateTime(charge.expiresDate, 'dd MMM HH:mm')}</PaymentDateTime>
          </PaymentsListItem>
        ))}
      </PaymentsContainer>
    :
    <>
    </>
  }

    </>
    
  );
};

export default Payments;
