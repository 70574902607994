import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { SET_CHARGE_CODE, SET_CHARGES } from 'store/actions';
import PrimaryButton from 'components/Buttons/PrimaryButton';
import { ChargeContainer, InputGroup, InputIcon } from './styled';
import InputValidIcon from 'assets/icons/InputValidIcon';
import { ChargeBody, ProductBody } from 'api/interfaces/chargeBody.interface';

export interface IFormProps {
  name: string;
  email: string;
  amount: any;
  transaction: string;
}
export interface IErrorsProps {
  name: string;
  email: string;
  amount: string;
  transaction: string;
}

const ChargeDetail: FC = () => {
  const [formData, setFormData] = useState<IFormProps>({
    name: '',
    email: '',
    amount: '',
    transaction: ''
  });
  const [errors, setErrors] = useState<IErrorsProps>({
    name: '',
    email: '',
    amount: '',
    transaction: ''
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useSelector((state: RootStateOrAny) => state?.app?.theme);
  const error: any[] = useSelector((state: RootStateOrAny) => state?.app?.error);
  const chargeCurrency: string = useSelector((state: RootStateOrAny) => state?.app?.chargeCurrency);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);

  const validate: any = (fieldValues = formData) => {
    let temp: any = { ...errors };
    if ('name' in fieldValues) temp.name = fieldValues.name ? '' : 'This field is required.';

    if ('amount' in fieldValues) temp.amount = fieldValues.amount ? '' : 'This field is required.';

    if ('transaction' in fieldValues)
      temp.transaction = fieldValues.transaction ? '' : 'This field is required.';

    if ('email' in fieldValues) {
      temp.email = fieldValues.email ? '' : 'Email is not valid.';
      if (fieldValues.email)
        temp.email = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(fieldValues.email)
          ? ''
          : 'Email is not valid';
    }
    setErrors({
      ...temp
    });
  };

  const formIsValid = (fieldValues = formData) => {
    const isValid =
      fieldValues.name &&
      fieldValues.amount &&
      fieldValues.transaction &&
      fieldValues.email &&
      Object.values(errors).every((x) => x === '');
    return isValid;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    validate({ [name]: value });
  };

  const handleSubmit = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (formIsValid()) {
      dispatch({ type: SET_CHARGE_CODE, payload: {
        amount: formData.amount,
        currency: chargeCurrency,
        name: "Charge",
        description: "Charge"
      } });
      dispatch({ type: SET_CHARGES });
      navigate(`/${merchantId}/charge-detail`);
    }
  };

  useEffect(() => {
    if (error) {
      navigate(`/${merchantId}/lock`);
    }
  }, [error]);

  return (
    <ChargeContainer>
      <form onSubmit={(event: any) => handleSubmit(event)}>
        <InputGroup themeColors={theme} status={formData.amount.length === 0 ? false : true}>
          <input
            type="number"
            pattern="([0-9]{1,3}).([0-9]{1,3})"
            name="amount"
            id="amount"
            placeholder="ENTER AMOUNT"
            onChange={handleChange}
          />
          <InputIcon>{formData.amount.length === 0 ? '' : <InputValidIcon />}</InputIcon>
        </InputGroup>
        <InputGroup themeColors={theme} status={formData.name.length === 0 ? false : true}>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="ENTER NAME"
            onChange={handleChange}
          />
          <InputIcon>{formData.name.length === 0 ? '' : <InputValidIcon />}</InputIcon>
        </InputGroup>
        <InputGroup
          themeColors={theme}
          status={formData.email.length > 0 && errors.email === '' ? true : false}
        >
          <input
            type="text"
            name="email"
            id="email"
            placeholder="ENTER EMAIL"
            onChange={handleChange}
          />
          <InputIcon>
            {formData.email.length > 0 && errors.email === '' ? <InputValidIcon /> : ''}
          </InputIcon>
        </InputGroup>
        <InputGroup
          themeColors={theme}
          status={formData.transaction.length === 0 ? false : true}
          style={{ marginBottom: '15px' }}
        >
          <input
            type="text"
            name="transaction"
            id="transaction"
            placeholder="TRANSACTION DETAILS"
            onChange={handleChange}
          />
          <InputIcon>{formData.transaction.length === 0 ? '' : <InputValidIcon />}</InputIcon>
        </InputGroup>
        <PrimaryButton borderColor={theme.primary} textColor={theme.primary} text="CREATE CHARGE" />
      </form>
    </ChargeContainer>
  );
};

export default ChargeDetail;
