/* eslint-disable react-hooks/exhaustive-deps */
import { PublicClientApplication } from '@azure/msal-browser';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { authResult } from 'config/authConfig';
import { loginRequest, signUpRequest } from 'index';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const SignUpSignIn = () => {
  const { instance, inProgress } = useMsal();
  const [searchParams] = useSearchParams();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    token();
    if (searchParams.get('signup') === 'true') {
      instance.loginRedirect(signUpRequest);
      return;
    }
    if (searchParams.get('signin') === 'true') {
      instance.loginRedirect(loginRequest);
      return;
    }
  }, [instance]);

  const token = async () => {
    const t = await authResult(instance as PublicClientApplication, isAuthenticated, inProgress);
    if (!t) return navigate('/');

    const decoded: JwtPayload & { extension_MerchantName: string } = jwtDecode(t);
    navigate(`/${decoded.extension_MerchantName}`);
  };

  return <div>SignUpSignIn</div>;
};

export default SignUpSignIn;
