import Axios from 'axios';
import { AsyncTask } from 'api/interfaces/asyncTask.interface';
import { ChargeBody } from 'api/interfaces/chargeBody.interface';

export const getCharge: (
  body: ChargeBody,
  token: string
) => Promise<AsyncTask> = async (body: ChargeBody, token: string) => {
  const { data } = await Axios.post(
    `${window.API_PATH}charges`,
    body, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }
  );
  return data;
};


export const getCryptocurrency: (
  code: string,
  token: string
) => Promise<AsyncTask> = async (code: string, token: string) => {
  const { data } = await Axios.get<AsyncTask>(
    `${window.API_PATH}charges/${code}`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }
  );
  return data;
};


export const getCryptocurrencyAddress: (
  chargeId: string,
  currency: string,
  token: string
) => Promise<AsyncTask> = async (chargeId: string, currency: string, token: string) => {
  const { data } = await Axios.put<AsyncTask>(
    `${window.API_PATH}charges/${chargeId}/address/${currency}`, {}, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }
  );
  return data;
};


export const getCharges: (
  token: string
) => Promise<AsyncTask> = async (token: string) => {
  const { data } = await Axios.get<AsyncTask>(
    `${window.API_PATH}charges?$orderBy=ExpiresDate desc`, {
      headers: {
        'Authorization': `Bearer ${token}` 
      }
    }
  );
  return data;
};