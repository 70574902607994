import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import Theme from 'assets/theme/class/theme';
import { PrimaryButton } from 'components/Buttons';
import { passwordResetRequest } from 'index';
import { FC } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { HomeContainer } from './styled';

const Home: FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const theme: Theme = useSelector((state: RootStateOrAny) => state.app.theme);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);

  // useEffect(() => {
  //   return () => {
  //     instance.logoutPopup();
  //     navigate('/lock');
  //   };
  // }, []);

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <HomeContainer themeColors={theme}>
        <PrimaryButton
          borderColor={theme.primary}
          textColor={theme.primary}
          text="BALANCES"
          clickHandler={() => navigate(`/${merchantId}/admin/balances`)}
        />
        <PrimaryButton
          borderColor={theme.primary}
          textColor={theme.primary}
          text="PRODUCTS"
          clickHandler={() => navigate(`/${merchantId}/admin/products`)}
        />
        <PrimaryButton
          borderColor={theme.primary}
          textColor={theme.primary}
          text="CASHIERS"
          clickHandler={() => navigate(`/${merchantId}/admin/cashiers`)}
        />
        <PrimaryButton
          borderColor={theme.primary}
          textColor={theme.primary}
          text="PASSWORD RESET"
          clickHandler={() => instance.loginRedirect(passwordResetRequest)}
        />
        <PrimaryButton
          borderColor={theme.primary}
          textColor={theme.primary}
          text="LOGOUT"
          clickHandler={() =>
            instance.logoutRedirect({
              postLogoutRedirectUri: `/${merchantId}/admin`
            })
          }
        />
      </HomeContainer>
    </MsalAuthenticationTemplate>
  );
};

export default Home;
