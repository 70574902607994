import { fontSizes, typography } from 'assets/theme/typography';
import styled from 'styled-components';

export const PaymentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const PaymentAmount = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  letter-spacing: 1px;

  span {
    font-size: ${fontSizes.xlarge};
  }
`;

export const PaymentCaption = styled.div`
  margin-bottom: 0.5rem;
  font-size: ${fontSizes.large};
`;

export const PaymentTotal = styled.div`
  margin-bottom: 0.5rem;
  font-size: ${fontSizes.xlarge};
`;

export const StateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 80%;
  font-size: 54px;
  text-align: center;
  ${typography.caption};
`;
