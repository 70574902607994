import { FormControl, InputLabel, MenuItem, Select, Switch } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import TextField from '@mui/material/TextField';
import AdminProductApi from 'api/admin/products.api';
import Theme from 'assets/theme/class/theme';
import { PrimaryButton } from 'components/Buttons';
import { FC, useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { PublicClientApplication } from '@azure/msal-browser';
import { authResult } from 'config/authConfig';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { ProductCategory } from 'api/interfaces/products.interface';

const ProductDetail: FC = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const adminProductsApi = new AdminProductApi('');
  const navigate = useNavigate();
  const params = useParams();
  const theme: Theme = useSelector((state: RootStateOrAny) => state.app.theme);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);

  const [product, setProduct] = useState<any>(null);
  const [categories, setCategories] = useState<ProductCategory[]>([]);

  useEffect(() => {
    retrieveProduct();
    retrieveCategories();
  }, []);

  const retrieveCategories: () => void = async () => {
    const token = await authResult(
      instance as PublicClientApplication,
      isAuthenticated,
      inProgress
    );
    const response = await adminProductsApi.getProductCategories(token);
    setCategories([{ id: '', categoryName: 'None' }, ...response]);
  };

  const retrieveProduct: () => void = async () => {
    const token = await authResult(
      instance as PublicClientApplication,
      isAuthenticated,
      inProgress
    );
    const id = params['id'];
    if (!id) return;
    const res = await adminProductsApi.getProduct(id, token);

    setProduct(res);
  };

  const saveProduct: () => void = async () => {
    const token = await authResult(
      instance as PublicClientApplication,
      isAuthenticated,
      inProgress
    );
    const id = params['id'];
    if (!id) return;
    await adminProductsApi.putProduct(id, product, token);

    navigate(`/${merchantId}/admin/products`);
  };

  return product ? (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '16px', width: '30rem' }}>
      <KeyboardBackspaceIcon
        fontSize="large"
        style={{ cursor: 'pointer' }}
        onClick={() => navigate(`/${merchantId}/admin/products`)}
      />
      <h2>{product?.name || ''}</h2>
      <FormControl fullWidth>
        <InputLabel
          id="category-label"
          sx={{
            '&[data-shrink="true"]': {
              transform: 'translate(12px, 3px) scale(0.75)'
            }
          }}
        >
          Category
        </InputLabel>
        <Select
          labelId="category-label"
          style={{ backgroundColor: 'white' }}
          label="Category"
          variant="filled"
          value={product?.productCategoryId}
          onChange={(e) => {
            e.preventDefault();
            const copy = { ...product };
            copy.productCategoryId = e.target.value;
            setProduct(copy);
          }}
        >
          {categories?.map((c) => (
            <MenuItem value={c.id} key={c.id}>
              {c.categoryName}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        style={{ backgroundColor: 'white' }}
        label="Description"
        variant="filled"
        value={product?.description}
        onChange={(e) => {
          e.preventDefault();
          const copy = { ...product };
          copy.description = e.target.value;
          setProduct(copy);
        }}
      />
      <TextField
        style={{ backgroundColor: 'white' }}
        label="Name"
        variant="filled"
        value={product?.name}
        onChange={(e) => {
          e.preventDefault();
          const copy = { ...product };
          copy.name = e.target.value;
          setProduct(copy);
        }}
      />
      <TextField
        style={{ backgroundColor: 'white' }}
        label="Value"
        variant="filled"
        type="number"
        value={product?.amount}
        onChange={(e) => {
          e.preventDefault();
          const copy = { ...product };
          copy.amount = e.target.value;
          setProduct(copy);
        }}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              sx={{
                '.MuiSwitch-track': {
                  backgroundColor: 'white'
                }
              }}
              color="success"
              checked={product?.isActive}
              onChange={(e: any) => {
                e.preventDefault();
                const copy = { ...product };
                copy.isActive = e.target.checked;
                setProduct(copy);
              }}
            />
          }
          label="Is active?"
        />
      </FormGroup>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'end' }}>
        <PrimaryButton
          borderColor={theme.primary}
          textColor={theme.primary}
          text="Save"
          fullWidth
          clickHandler={() => saveProduct()}
        />
      </div>
    </div>
  ) : (
    <p>Loading...</p>
  );
};

export default ProductDetail;
