import { checkURL } from 'lib/helpers/checkURL';
import { Helmet } from 'react-helmet';
import theYardLogo from 'assets/metatags/theYardLogo.png';
import theYardFavicon32 from 'assets/metatags/theYard-32x32.png';
import theYardFavicon16 from 'assets/metatags/theYard-16x16.png';
import BitPayLogo from 'assets/metatags/Bitpay-logo.png';
import BitPayFavicon32 from 'assets/metatags/favicon-32x32.png';
import BitPayFavicon16 from 'assets/metatags/favicon-16x16.png';

const MetaTags = () => {
  const renderTheYard: () => JSX.Element = () => (
    <Helmet title="The Yard - Crypto Point of Sale System">
      <meta name="description" content="" />
      <meta name="image" property="og:image" content={theYardLogo} />
      <meta name="twitter:image" content={theYardLogo} />
      <link rel="icon" type="image/png" sizes="32x32" href={theYardFavicon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={theYardFavicon16} />
      <link rel="apple-touch-icon" href={theYardLogo} />
      <link rel="apple-touch-startup-image" href={theYardLogo} />
      <meta name="application-name" content="The Yard" />
    </Helmet>
  );
  const renderBitPay: () => JSX.Element = () => (
    <Helmet title="BitPay - Crypto Point of Sale System">
      <meta name="description" content="" />
      <meta name="image" property="og:image" content={BitPayLogo} />
      <meta name="twitter:image" content={BitPayLogo} />
      <link rel="icon" type="image/png" sizes="32x32" href={BitPayFavicon32} />
      <link rel="icon" type="image/png" sizes="16x16" href={BitPayFavicon16} />
      <link rel="apple-touch-icon" href={BitPayLogo} />
      <link rel="apple-touch-startup-image" href={BitPayLogo} />
      <meta name="application-name" content="BitPay" />
    </Helmet>
  );
  return checkURL() ? renderTheYard() : renderBitPay();
};

export default MetaTags;
