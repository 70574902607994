import React from 'react';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { navData } from 'lib/data/menuItems';
import { NavLink } from 'react-router-dom';
import NavButton from 'components/Buttons/NavButton';
import { NavigationContainer } from './styled';

type Props = {};

const Navigation = (props: Props) => {
  const dispatch = useDispatch();
  const theme = useSelector((state: RootStateOrAny) => state.app.theme);
  const merchantId: any[] = useSelector((state: RootStateOrAny) => state?.app?.merchantId);

  return (
    <NavigationContainer themeColors={theme}>
      <ul>
        {navData.map(({ name, path, icon, onClick }, key) => (
          <li key={key}>
            <NavLink to={`/${merchantId}/${path}` || ''} onClick={() => onClick(dispatch)}>
              <NavButton text={name} icon={icon} />
            </NavLink>
          </li>
        ))}
      </ul>
    </NavigationContainer>
  );
};

export default Navigation;
