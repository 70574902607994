import { checkURL } from 'lib/helpers/checkURL';

export const fontWeights = {
  black: 900,
  bold: 700,
  regular: 400
};

export const fontSizes = {
  regular: '14px',
  small: '12px',
  large: '18px',
  xlarge: '21px',
  xxlarge: '30px'
};

export const font = {
  heading: `h1,
  h2,
  h3,
  h4,
  h5`,
  body: `*:not(h1, h2, h3, h4, h5) {font-family: '${
    checkURL() ? 'SF Pro Display' : 'Roboto'
  }', sans-serif;}`
};

export const typography = {
  bodyRegular: `font-size: ${fontSizes.regular}`,
  navLink: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.regular}`,
  bodyBold: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.bold}`,
  link: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.regular}; text-decoration: underline; cursor: pointer;`,
  caption: `font-family: '${checkURL() ? 'Walting Font' : 'Roboto'}', sans-serif !important;`,
  fontRegular: `font-family: '${checkURL() ? 'SF Pro Display' : 'Roboto'}', sans-serif !important;`
};
