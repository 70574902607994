import { useEffect, useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';
import QRCode from "react-qr-code";
import loadingSpinner from 'assets/lottie-animations/loading-spinner.json';
import {
  QRCodeContainer,
  QRCodeAddress,
  QRCodeImg,
  QRCodeBorderTop,
  QRCodeBorderLeftTop,
  QRCodeBorderRightTop,
  QRCodeBorderBottom,
  QRCodeBorderLeftBottom,
  QRCodeBorderRightBottom,
  LoadingLottieAnimation
} from './styled';

type Props = {
  currency: string;
};

const QrCodeWrapper = (props: Props) => {
  const theme = useSelector((state: RootStateOrAny) => state?.app?.theme);
  const { currency } = props;
  const cryptocurrencyAddress: any = useSelector(
    (state: RootStateOrAny) => state?.app?.charge?.addresses
  );
  const cryptocurrencyPricing: any = useSelector(
    (state: RootStateOrAny) => state?.app?.charge?.pricing
  );

  const [blockchainAddress, setBlockchainAddress] = useState<any>('');
  const [blockchainAmount, setBlockchainAmount] = useState<any>('');

  const copyClipboard = async () => {
    await navigator.clipboard.writeText(blockchainAddress);
  };

  const setAddress = (currency: any) => {
    setBlockchainAddress(cryptocurrencyAddress[currency]?.address);
  };
  const setPricing = (currency: any) => {
    setBlockchainAmount(cryptocurrencyPricing[currency]?.amount);
  };
  const getQrCodeChl = (currency: string, address: string, amount: number) => {
    if (currency === 'ETH') {
      return `${currencies[currency]}:${blockchainAddress}?value=${blockchainAmount * 1000000000000000000}`;
    }

    if (currency === 'USDC') {
      return `${currencies[currency]}:0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48/transfer?address=${blockchainAddress}&uint256=${
        blockchainAmount * 1000000
      }`;
    }

    if (currency === 'DAI') {
      return `${currencies[currency]}:0x6B175474E89094C44Da98b954EedeAC495271d0F/transfer?address=${blockchainAddress}&uint256=${
        blockchainAmount * 1000000000000000000
      }`;
    }

    if (currency === 'SHIB') {
      return `${currencies[currency]}:0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce/transfer?address=${blockchainAddress}&uint256=${
        blockchainAmount * 1000000000000000000
      }`;
    }

    if (currency === 'USDT') {
      return `${currencies[currency]}:0xdac17f958d2ee523a2206206994597c13d831ec7/transfer?address=${blockchainAddress}&uint256=${
        blockchainAmount * 1000000
      }`;
    }

    return `${currencies[currency]}:${blockchainAddress}?amount=${blockchainAmount}`;
  };

  useEffect(() => {
    if (currency) {
      setAddress(currency);
      setPricing(currency);
    }
  }, [currency, cryptocurrencyAddress, cryptocurrencyPricing]);

  const names: any = {
    BTC: 'Bitcoin',
    ETH: 'Ethereum',
    USDC: 'USD Coin',
    DOGE: 'Dogecoin',
    LTC: 'Litecoin',
    DAI: 'Dai',
    BCH: 'Bitcoin Cash',
    SHIB: "Shiba Inu",
    USDT: "Tether USD",
    SOL: "Solana"
  };

  const currencies: any = {
    BTC: 'bitcoin',
    ETH: 'ethereum',
    USDC: 'ethereum',
    DOGE: 'dogecoin',
    LTC: 'litecoin',
    DAI: 'ethereum',
    BCH: 'bitcoincash',
    SHIB: "ethereum",
    USDT: "ethereum",
    SOL: "solana"
  };

  return (
    <QRCodeContainer>
      <QRCodeBorderTop>
        <QRCodeBorderLeftTop themeColors={theme}></QRCodeBorderLeftTop>
        <QRCodeBorderRightTop themeColors={theme}></QRCodeBorderRightTop>
      </QRCodeBorderTop>
      <QRCodeImg>
        {blockchainAddress ? (
          <QRCode
            value={getQrCodeChl(currency, blockchainAddress, blockchainAmount)}
            size={240}
            fgColor="#232538"
          />
          // <img
          //   src={`https://chart.googleapis.com/chart?cht=qr&chs=225x225&chl=${encodeURIComponent(
          //     getQrCodeChl(currency, blockchainAddress, blockchainAmount)
          //   )}`}
          //   alt={`QR Code for ${currency}`}
          // />
        ) : (
          currency !== '' && <LoadingLottieAnimation loop animationData={loadingSpinner} />
        )}
      </QRCodeImg>
      <QRCodeBorderBottom>
        <QRCodeBorderLeftBottom themeColors={theme}></QRCodeBorderLeftBottom>
        <QRCodeBorderRightBottom themeColors={theme}></QRCodeBorderRightBottom>
      </QRCodeBorderBottom>
      <Tooltip title="copy">
        <QRCodeAddress onClick={copyClipboard}>
          {blockchainAddress && `${names[currency].toUpperCase()} ADDRESS:`} <br />
          {blockchainAddress}
        </QRCodeAddress>
      </Tooltip>
    </QRCodeContainer>
  );
};

export default QrCodeWrapper;
