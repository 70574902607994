export default class Theme {
  constructor(
    public readonly primary: string,
    public readonly secondary: string,
    public readonly button: string,
    public readonly inputBorder: string,
    public readonly shadow: string,
    public readonly footerBorder: string,
    public readonly footerBackground: string,
    public readonly orderBackground: string,
    public readonly error: string,
    public readonly warning: string,
    public readonly success: string,
    public readonly QRCodeBorder: string,
    public readonly keyboardBackground: boolean,
    public readonly headerLogoTop: string,
    public readonly headerLogoHeight: string,
    public readonly headerButtonTop: string,
    public readonly paymentContainerTop: string
  ) {}
}
