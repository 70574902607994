import styled, { css } from 'styled-components';
import { fontSizes, typography } from 'assets/theme/typography';
import { isMobile } from 'lib/helpers/mobile';
import Drawer from '@mui/material/Drawer';

type OrderListProps = {
  show: boolean;
};

interface ThemeProps {
  themecolors: any;
}

export const OrderListContainer = styled.div`
  display: flex;
`;

export const OrderThemeContainer = styled.div``;

export const OrderListViewContainer = styled(Drawer)<ThemeProps>`
  .MuiPaper-root {
    width: ${isMobile() ? '100%' : '40rem'};
    background-color: ${({ themecolors }) => themecolors?.orderBackground || '#00000080'};
    border-bottom-left-radius: 40px;
    border-top-left-radius: 40px;
    ${typography.fontRegular};
    padding-left: 0.5rem;
  }
  .MuiButtonBase-root {
    color: white;
    padding-top: 2rem;
    justify-content: flex-start;
  }
  .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

export const OrderItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 20px;
  color: white;
`;

export const OrderItems = styled.p`
  margin: 0;
  color: white;
  font-size: ${fontSizes.large};
`;

export const OrderListButton = styled.div<OrderListProps>`
  cursor: pointer;
  position: absolute;
  padding: 0.5rem 1rem;
  border: 1px solid white;
  border-bottom-left-radius: 20px;
  border-top-left-radius: 20px;
  right: 0;
  top: 6rem;
  align-items: center;
  gap: 0.5rem;
  font-size: ${isMobile() ? fontSizes.regular : fontSizes.large};
  display: ${({ show }) => (show ? 'flex' : 'none')};
`;

export const OrderListTitle = styled.h3`
  margin: 0;
  color: white;
  text-align: center;
  margin-bottom: 1rem;
`;
