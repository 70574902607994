import Theme from '../class/theme';

export const theme = new Theme(
  '#F3F4F6',
  '#000000',
  '#FFFFFFF',
  '#777777',
  '#21234d',
  '#777777',
  '#232538',
  'rgba(33,35,77 ,0.87)',
  '#E4333E',
  '#F29D39',
  '#15B672',
  '#974E7D',
  true,
  '160px',
  '270px',
  '110px',
  '170px'
);
